<table mat-table class="wf-table" [dataSource]="dataSource" matSort [matSortActive]="defaultSort ?? 'dueDate'" matSortDirection="asc"
    matSortDisableClear [trackBy]="trackById" wfTableFocus>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'actions.description' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <a class="multiline-description" (click)="viewAction(element)" wfEmptyLink>
                {{element.description }}
            </a>
            <app-origin-table-button *ngIf="element.origin" [origin]="element.origin"></app-origin-table-button>
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.mat-column-status-display]="readonly">
            {{'actions.status' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" [class.mat-column-status-display]="readonly">
            <app-action-progress-update *ngIf="!readonly" [action]="element" [disabled]="disabled"
                (updated)="actionUpdated($event)">
            </app-action-progress-update>
            <app-action-progress *ngIf="readonly" [progress]="element.progress"></app-action-progress>
        </td>
    </ng-container>
    <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.priority' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-priority [priority]="element.priority"></app-priority>
        </td>
    </ng-container>
    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
            @if (filterMode === 'owner') {
                <app-table-user-filter (selected)="applyOwnerFilter($event)" withSortHeader>
                    {{ 'actions.owner' | translate }}
                </app-table-user-filter>
            } @else {
                <div mat-sort-header>
                    {{'actions.owner' | translate}}
                </div>
            }
        </th>
        <td mat-cell *matCellDef="let element">
            <app-delegated-user-display [entity]="element">
                {{ getUserName(element.owner) }}
                <wf-private-indicator *ngIf="element.isPrivateAction"></wf-private-indicator>
            </app-delegated-user-display>
        </td>
    </ng-container>
    <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.creator' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ getUserName(element.creator) }}
        </td>
    </ng-container>
    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.team' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-team-display [team]="element.team" [company]="element.company"></app-team-display>
        </td>
    </ng-container>
    <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.origin' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-origin-details [origin]="element.origin" [defaultOrigin]="defaultOrigin"></app-origin-details>
        </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.createdDate' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.createdDateLocal | dateFormat}}
        </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.dueDate' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.dueDateLocal | dateFormat}}

            <ng-container *ngIf="fromMeeting && shouldShowDueDateHistory(element)">
                <i class="due-date-history fas fa-calendar-plus" [wfTemplateTooltip]="dueDateTooltip"></i>
                <ng-template #dueDateTooltip>
                    <ul class="due-date-history-tooltip">
                        <li *ngFor="let date of element.dueDateHistory">
                            {{ date.dueDateLocal | dateFormat }}
                        </li>
                    </ul>
                </ng-template>
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.department' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.department?.name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.category' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            @if (element.category; as category) {
                <app-category-display [category]="category"></app-category-display>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="actionDiscuss">
        <th mat-header-cell *matHeaderCellDef [class.mat-column-discuss]="childButtonMode === 'discuss'">
            <ng-container *ngIf="childButtonMode === 'actionDiscuss'">
                {{'actionDiscuss' | translate}}
            </ng-container>
            <ng-container *ngIf="childButtonMode === 'discuss'">
                {{'actions.discuss' | translate}}
            </ng-container>
        </th>
        <td mat-cell *matCellDef="let element" [class.mat-column-discuss]="childButtonMode === 'discuss'">
            <app-add-action-child-action-button *ngIf="childButtonMode === 'actionDiscuss'"
                [source]="element" [disabled]="disabled"
                (actionAdded)="childActionAdded(element)">
            </app-add-action-child-action-button>
            <app-add-action-child-discussion-button [source]="element" [disabled]="disabled"
                (discussionAdded)="discussionAdded(element)">
            </app-add-action-child-discussion-button>
        </td>
    </ng-container>
    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>
            <ng-content select="app-table-settings-menu"></ng-content>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="button-holder">
                <app-view-action-solution-button [action]="element" *ngIf="isActionForSolution(element)">
                </app-view-action-solution-button>

                <app-feed-button (openFeed)="openFeed(element)" [reference]="element.feedPartition">
                </app-feed-button>

                <app-edit-action-menu *ngIf="menuType === 'edit'"
                    [action]="element" [disabled]="disabled"
                    (updated)="actionUpdated($event)" (deleted)="actionDeleted(element)"
                    (copied)="childActionAdded(element)">
                </app-edit-action-menu>

                <ng-container *ngTemplateOutlet="extraOptionsTemplate; context: { $implicit: element }"></ng-container>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns$ | async" [wfRowFocus]="row"
        [wfRowFocusIndex]="i"></tr>

    <ng-container *matNoDataRow>
        <tr class="mat-mdc-row" *ngIf="asyncDataSource?.hasNoVisibleData ?? true">
            <td [attr.colSpan]="(displayedColumns$ | async)?.length">
                <span class="no-records">
                    {{'NoRecordsFound.noRecordsFound!' | translate}}
                </span>
            </td>
        </tr>
    </ng-container>
    <ng-container *matFooterRowDef="[]">
        <tr *ngIf="asyncDataSource">
            <td [attr.colSpan]="(displayedColumns$ | async)?.length">
                <wf-table-scroll-footer [dataSource]="asyncDataSource"></wf-table-scroll-footer>
            </td>
        </tr>
    </ng-container>
</table>