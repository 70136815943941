import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { SimpleCategoryDto } from "@api";

@Component({
    selector: "app-category-display",
    templateUrl: "./category-display.component.html",
    styleUrl: "./category-display.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryDisplayComponent {
    readonly category = input.required<SimpleCategoryDto | string>();
    readonly subCategory = input<string | null | undefined>(null);

    readonly categoryName = computed(() => {
        const category = this.category();
        if (typeof category === "string") return category;
        return category.description;
    });

    readonly subCategoryName = computed<string | null>(() => {
        const subCategory = this.subCategory();
        if (subCategory) return subCategory;

        const category = this.category();
        if (typeof category === "string") return null;
        return category.subCategory?.description ?? null;
    });
}
