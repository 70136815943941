import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionAndSolutionDto, GetActionDto, GetNumberDto, GlobalItemScheduleDto, NumberRecordDetailDto, NumberSourceDto, NumberTargetDto, PlanNumbersApi, UpdateNumbersApi } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, catchError, distinctUntilChanged, EMPTY, filter, finalize, first, map, Observable, of, share, Subject, Subscription, switchMap, tap } from "rxjs";

import { DeleteNumberDialogComponent, EditNumberDialogComponent } from "~/app/quarterly-planning/dialogs";
import { DeploymentMigrationService } from "~/app/quarterly-planning/services";
import { FeedAdapterBuilder, FeedScope, SimpleFeedScope } from "~feed";
import { AccessService, AccessState } from "~services/access.service";
import { FeatureContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import {
    ActionStateService, DiscussionStateService, mergeRecordUpdatesFrom, NumberStateEvent, NumberStateService
} from "~services/state";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { CaptureMethod, EntityType, PageName, PlanningStatus } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeInAnimationBuilder } from "~shared/util/animations";
import { mergeChildUpdatesFrom } from "~shared/util/children-state-helper";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { getNumberUpdateScheduleDescription, getRelevantWeek, hasLowerBound, hasUpperBound, shouldShowWorkInstructionVideo } from "~shared/util/number-helper";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { sortCompanyTeam, sortNumber } from "~shared/util/sorters";
import { getDayOfWeekNameKey, getNumberTargetTypeNameKey, getNumberTypeNameKey } from "~shared/util/translation-helper";
import { cloneAndOmit, exhaustiveStringTuple } from "~shared/util/type-utils";
import { getUserName } from "~shared/util/user-helper";

import { HomepageScaffoldComponent } from "../homepage-scaffold/homepage-scaffold.component";

export type NumberDto = GetNumberDto | NumberRecordDetailDto;

const isRecord = (number: NumberDto): number is NumberRecordDetailDto =>
    "week" in number;

const editAllowed = (number: NumberDto | null): number is GetNumberDto =>
    !!number && !isRecord(number);

type NumberRecordProps = Exclude<keyof NumberRecordDetailDto, keyof GetNumberDto>;

const RECORD_PROPERTIES = exhaustiveStringTuple<NumberRecordProps>()(
    "week", "updateDue", "updateDueLocal", "recordTarget", "result", "notes", "automaticRetrievalFailed");

const convertRecordToNumber = (record: NumberRecordDetailDto): GetNumberDto =>
    cloneAndOmit(record, RECORD_PROPERTIES);

@Component({
    selector: "app-number-homepage",
    templateUrl: "./number-homepage.component.html",
    styleUrls: ["./number-homepage.component.scss"],
    providers: [
        SimpleFeedScope,
        {
            provide: FeedScope,
            useExisting: SimpleFeedScope,
        },
    ],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
    ],
    standalone: false,
})
export class NumberHomepageComponent<TNumber extends NumberDto> extends WithDestroy() implements OnInit, OnDestroy {

    @Input() set number(value: TNumber | null) {
        this.numberSubject.next(value);
        this.simpleFeedScope.adapter = value ? this.feedAdapterBuilder.buildForNumber(value) : null;
    }

    get number(): TNumber | null {
        return this.numberSubject.value;
    }

    @Input() set allowEdit(value: BooleanInput) {
        this.allowEditInternal = coerceBooleanProperty(value);
    }

    get allowEdit(): boolean {
        return this.allowEditInternal;
    }

    @Output() numberChange = new EventEmitter<TNumber>();
    @Output() numberDeleted = new EventEmitter<TNumber>();

    @Output() scheduleChange = new EventEmitter<GlobalItemScheduleDto>();

    @ViewChild(HomepageScaffoldComponent) scaffold?: HomepageScaffoldComponent;

    readonly schedule$: Observable<GlobalItemScheduleDto[]>;

    readonly source$: Observable<NumberSourceDto | null>;

    readonly dailyChildren$: Observable<NumberRecordDetailDto[] | null>;
    readonly deployedChildren$: Observable<NumberRecordDetailDto[] | null>;

    readonly records$: Observable<NumberRecordDetailDto[]>;
    isLoadingRecords = true;
    recordsHasError = false;

    readonly actions$: Observable<GetActionDto[]>;
    isLoadingActions = true;
    actionsHasError = false;

    readonly discussions$: Observable<DiscussionAndSolutionDto[]>;
    isLoadingDiscussions = true;
    discussionsHasError = false;

    readonly access$: Observable<AccessState>;

    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
    readonly getUserName = getUserName;
    readonly getTypeNameKey = getNumberTypeNameKey;
    readonly getTargetTypeNameKey = getNumberTargetTypeNameKey;

    readonly isRecord = isRecord;
    readonly shouldShowWorkInstructionVideo = shouldShowWorkInstructionVideo;

    readonly workInstructionEnabled = inject(FeatureContext).workInstructionEnabled;
    readonly dailyUpdatedNumbersEnabled = inject(FeatureContext).dailyUpdatedNumbersEnabled;
    readonly deployedNumbersEnabled = inject(FeatureContext).deployedNumbersEnabled;

    private allowEditInternal = false;

    private readonly _records$: Observable<NumberRecordDetailDto[]>;

    private readonly numberSubject = new BehaviorSubject<TNumber | null>(null);
    private readonly selectScheduleSubject = new Subject<GlobalItemScheduleDto>();
    private readonly refreshScheduleSubject = new BehaviorSubject<void>(undefined);
    private readonly refreshRecordsSubject = new BehaviorSubject<void>(undefined);
    private readonly refreshActionsSubject = new BehaviorSubject<void>(undefined);
    private readonly refreshDiscussionsSubject = new BehaviorSubject<void>(undefined);
    private readonly reloadNumberSubject = new Subject<void>();

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly updateNumbersApi: UpdateNumbersApi,
        private readonly deploymentMigrationService: DeploymentMigrationService,
        private readonly numberStateService: NumberStateService,
        private readonly actionStateService: ActionStateService,
        private readonly discussionStateService: DiscussionStateService,
        private readonly simpleFeedScope: SimpleFeedScope,
        private readonly feedAdapterBuilder: FeedAdapterBuilder,
        private readonly accessService: AccessService,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly translate: TranslateService,
    ) {
        super();

        this.access$ = this.numberSubject.pipe(
            switchMap(number => {
                if (!number) return of(AccessState.disabled);
                if (number.isDelegated || number.updateDay != null) return of({ canRead: true, canEdit: false, canDelete: false });
                return this.accessService.getAccessState(number.company.id, number.team.id, PageName.quarterlyPlanning).pipe(
                    map(access => ({
                        ...access,
                        canDelete: access.canDelete && !number.source,
                    })),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.schedule$ = this.numberSubject.pipe(
            withRefresh(this.refreshScheduleSubject),
            switchMap(number => {
                if (!number) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.planNumbersApi.getNumberSchedule(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    number.id,
                ).pipe(
                    catchError(() => of([])),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.source$ = this.numberSubject.pipe(
            map(number => number?.source ?? null),
            shareReplayUntil(this.destroyed$),
        );

        this.dailyChildren$ = this.numberSubject.pipe(
            switchMap(number => {
                if (!number?.dailyUpdateDefinition) return of(null);

                const relevantWeek = getRelevantWeek(number);
                if (relevantWeek == null) return of(null);

                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.updateNumbersApi.getDailyChildren(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    relevantWeek,
                    number.id,
                ).pipe(
                    mergeRecordUpdatesFrom(data => this.numberStateService.eventsForNumbers(...data)),
                    map(numbers => numbers.sort(sortNumber.ascending(n => n.updateDay))),
                    catchError(() => of(null)),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.deployedChildren$ = this.numberSubject.pipe(
            switchMap(number => {
                if (!number || number.captureMethod !== CaptureMethod.deployed) return of(null);

                const relevantWeek = getRelevantWeek(number);
                if (relevantWeek == null) return of(null);

                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.updateNumbersApi.getDeployedChildren(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    relevantWeek,
                    number.id,
                ).pipe(
                    mergeRecordUpdatesFrom(data => this.numberStateService.eventsForNumbers(...data)),
                    map(numbers => numbers.sort(sortCompanyTeam.ascending())),
                    catchError(() => of(null)),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        const distinctNumber$ = this.numberSubject.pipe(
            distinctUntilChanged((a, b) => a?.id === b?.id),
        );

        this.actions$ = distinctNumber$.pipe(
            withRefresh(this.refreshActionsSubject),
            tap(() => {
                this.isLoadingActions = true;
                this.actionsHasError = false;
            }),
            switchMap(number => {
                if (!number) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return (!number.actionsCount ? of([]) : this.planNumbersApi.getActionsForNumber(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    number.id,
                )).pipe(
                    mergeChildUpdatesFrom(this.actionStateService.events$, number.id, EntityType.number),
                    catchError(() => {
                        this.actionsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingActions = false),
            shareReplayUntil(this.destroyed$),
        );

        this.discussions$ = distinctNumber$.pipe(
            withRefresh(this.refreshDiscussionsSubject),
            tap(() => {
                this.isLoadingDiscussions = true;
                this.discussionsHasError = false;
            }),
            switchMap(number => {
                if (!number) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return (!number.discussionsCount ? of([]) : this.planNumbersApi.getDiscussionsForNumber(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    number.id,
                )).pipe(
                    mergeChildUpdatesFrom(this.discussionStateService.events$, number.id, EntityType.number),
                    catchError(() => {
                        this.discussionsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingDiscussions = false),
            shareReplayUntil(this.destroyed$),
        );

        this._records$ = distinctNumber$.pipe(
            withRefresh(this.refreshRecordsSubject),
            tap(() => {
                this.isLoadingRecords = true;
                this.recordsHasError = false;
            }),
            switchMap(number => {
                if (!number) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.planNumbersApi.getNumberRecords(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    number.id,
                ).pipe(
                    mergeRecordUpdatesFrom(data => this.numberStateService.eventsForNumbers(...data)),
                    catchError(() => {
                        this.recordsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingRecords = false),
            share(),
        );
        this.records$ = this._records$.pipe(shareReplayUntil(this.destroyed$));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.numberSubject.pipe(
            switchMap(number => !number ? EMPTY : this.numberStateService.eventsForNumbers(number)),
        ).subscribe(this.handleStateEvent));

        // Reload all records for the number, and re-emit the matching week as updated.
        this.subscriptions.add(this.reloadNumberSubject.pipe(
            tap(() => this.refreshRecordsSubject.next()),
            switchMap(() => this._records$.pipe(first())),
            filter(Boolean),
        ).subscribe(records => {
            const number = this.number;
            if (!number) return;
            const week = isRecord(number) ? number.week : number.latestWeek;
            if (week == null) return;
            const matchingRecord = records.find(r => r.id === number.id && r.week === week);
            if (!matchingRecord) return;
            if (isRecord(number)) {
                this.afterUpdatedInternal(matchingRecord as TNumber);
            } else {
                const newNumber = convertRecordToNumber(matchingRecord);
                this.afterUpdatedInternal({
                    ...newNumber,
                    // Keep the old feed partition
                    feedPartition: number.feedPartition,
                } as TNumber);
            }
        }));

        this.subscriptions.add(this.selectScheduleSubject.pipe(
            switchMap(schedule => {
                const number = this.number;
                if (!number) return EMPTY;
                CommonFunctions.setLoader(true);
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.planNumbersApi.getNumber(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: schedule.financialYear, quarter: schedule.planningPeriod }),
                    schedule.id,
                ).pipe(
                    catchError(() => {
                        this.notificationService.errorUnexpected();
                        return EMPTY;
                    }),
                    finalize(() => CommonFunctions.setLoader(false)),
                );
            }),
        ).subscribe(number => this.number = number as TNumber));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    selectSchedule = (schedule: GlobalItemScheduleDto) => {
        this.scheduleChange.emit(schedule);
        this.selectScheduleSubject.next(schedule);
    };

    view = () => {
        const number = this.number;
        if (!number) return;

        EditNumberDialogComponent.openForEdit(this.dialog, number, undefined, /* readonly: */ true)
            .afterClosed().subscribe(res => res ? this.afterUpdated(res as TNumber) : null);
    };

    edit = () => {
        const number = this.number;
        if (!editAllowed(number)) return;
        if (number.planningStatus === PlanningStatus.locked) {
            // Show the warning, but continue to show the read-only dialog
            this.notificationService.warning("numbers.editLockWarning", undefined, undefined, true);
        }
        EditNumberDialogComponent.openForEdit(this.dialog, number)
            .afterClosed().subscribe(res => res ? this.afterUpdated(res as TNumber) : null);
    };

    delete = () => {
        const number = this.number;
        if (!editAllowed(number)) return;
        if (number.planningStatus === PlanningStatus.locked) {
            this.notificationService.warning("numbers.deleteLockWarning", undefined, undefined, true);
            return;
        }
        DeleteNumberDialogComponent.open(this.dialog, number)
            .afterClosed().subscribe(res => res ? this.afterDeleted() : null);
    };

    canMigrate = () => editAllowed(this.number) && this.deploymentMigrationService.canMigrate(this.number);

    migrateNumber = () => {
        const number = this.number;
        if (!editAllowed(number)) return;
        this.deploymentMigrationService.migrateNumber(number).subscribe(res =>
            res && this.afterUpdated(res as TNumber));
    };

    canAdopt = () => editAllowed(this.number) && this.deploymentMigrationService.canAdopt(this.number);

    adoptNumber = () => {
        const number = this.number;
        if (!editAllowed(number)) return;
        this.deploymentMigrationService.adoptNumber(number).subscribe(res =>
            res && this.afterUpdated(res as TNumber));
    };

    canCopyBackwards = () => editAllowed(this.number) && this.deploymentMigrationService.canCopyBackwards(this.number);

    copyBackwards = () => {
        const number = this.number;
        if (!editAllowed(number)) return;
        this.deploymentMigrationService.copyBackwards(number).subscribe(res =>
            res && this.refreshScheduleSubject.next());
    };

    refreshActions = () => this.refreshActionsSubject.next();

    isTargetSet = (target: NumberTargetDto | undefined): boolean =>
        hasUpperBound(target) || hasLowerBound(target);

    isDeployedNumber = (number: NumberDto): boolean => !!number.source && number.updateDay == null;
    isDailyNumber = (number: NumberDto): boolean => !!number.source && number.updateDay != null;

    allowsUpdater = (number: NumberDto) => number.captureMethod === CaptureMethod.manual;

    getUpdateScheduleDescription = (number: NumberDto): string | null =>
        getNumberUpdateScheduleDescription(this.translate, number);

    recordUpdated = (record: NumberRecordDetailDto) => {
        const number = this.number;
        if (!number) return;

        if (isRecord(number) && record.week === number.week) {
            // We've updated the result for the selected week.
            // No further calculation needs to be done - emit as is.
            this.afterUpdatedInternal(record as TNumber);
        } else {
            // The updated week is not the selected week.
            // Reload all records, and notify an update for the matching week.
            this.reloadNumberSubject.next();
        }
    };

    afterUpdated = (number: TNumber) => {
        this.afterUpdatedInternal(number);
        this.refreshRecordsSubject.next();
    };

    afterDeleted = () => this.numberDeleted.emit(this.number ?? undefined);

    private afterUpdatedInternal = (number: TNumber) => {
        if (number === this.number) return;
        this.number = number;
        this.numberChange.emit(number);
        this.scaffold?.refreshFeed();
    };

    private handleStateEvent = (event: NumberStateEvent) => {
        switch (event.type) {
            case "added": // We should never get an added event, but treat it as if updated for simplicity
            case "updated": {
                const number = this.number;
                if (!number) return;

                if (isRecord(number)) {
                    if (event.item.week === number.week) {
                        // The result for the selected week has been updated.
                        this.afterUpdatedInternal(event.item as TNumber);
                    }
                } else {
                    if (event.item.week === number.latestWeek) {
                        const newNumber = convertRecordToNumber(event.item);
                        this.afterUpdatedInternal({
                            ...newNumber,
                            // Keep the old feed partition
                            feedPartition: number.feedPartition,
                        } as TNumber);
                    }
                }
                break;
            }
            case "deleted":
                this.afterDeleted();
                break;
        }
    };
}
