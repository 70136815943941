<form [formGroup]="form" (submit)="save()" #ngForm="ngForm">
    <h4 mat-dialog-title>{{ 'reports.report' | translate }}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{ 'reports.description' | translate }}</mat-label>
                    <textarea matInput type="text" formControlName="description" name="description" autocomplete="off"
                        required maxlength="250" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
                    <mat-error *ngIf="descriptionControl.hasError('required')"
                        translate="reports.descriptionRequired"></mat-error>
                    <mat-error *ngIf="descriptionControl.hasError('maxlength')"
                        translate="reports.descriptionMaxLength"
                        [translateParams]="descriptionControl.errors?.maxlength"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.team' | translate }}</mat-label>
                    @if (teamControl.enabled) {
                        <app-auto-select name="team" formControlName="team" [options]="teams$ | async"
                            [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData" required>
                            <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getTeamDisplay(teamControl.value)" disabled required />
                    }
                    <mat-error *ngIf="!teamControl.valid"
                        translate="reports.teamRequired"></mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.qtr' | translate }}</mat-label>
                    <wf-quarter-field name="quarter" formControlName="quarter"
                        [companyId]="teamControl.value?.company?.id" required>
                    </wf-quarter-field>
                    <mat-error *ngIf="quarterControl.hasError('required')"
                        translate="reports.qtrRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.collectionType' | translate }}</mat-label>
                    <mat-select formControlName="collectionType" name="collectionType" required disableOptionCentering>
                        <mat-option *ngFor="let type of collectionTypes" [value]="type">
                            {{ getCollectionTypeNameKey(type) | translate }}
                        </mat-option>
                    </mat-select>
                    <wf-help-indicator [wfTemplateTooltip]="collectionTypeTooltip" matTooltipClass="light-tooltip" matSuffix></wf-help-indicator>
                    <ng-template #collectionTypeTooltip>
                        <dl class="collection-type-tooltip">
                            <dt translate="reportCollectionType.standard"></dt>
                            <dd translate="reportCollectionType.standardDescription"></dd>
                            
                            <dt translate="reportCollectionType.externallyUpdated"></dt>
                            <dd translate="reportCollectionType.externallyUpdatedDescription"></dd>
                        </dl>
                    </ng-template>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.planningStatus' | translate }}</mat-label>
                    <mat-select formControlName="reportStatus" name="reportStatus" required disableOptionCentering>
                        <mat-option *ngFor="let status of planningStatuses" [value]="status">
                            {{ getPlanningStatusNameKey(status) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <fieldset *ngIf="hasPermanentLinks" @fadeExpand [@.disabled]="disableAnimations">
            <legend>{{'reports.permanentLinks' | translate}}</legend>
            <div class="row">
                <div class="col-sm-12">
                    <mat-error class="permanent-links-error" @fadeIn
                        *ngIf="permanentLinksControl.hasError('required') && (ngForm.submitted || permanentLinksControl.dirty || permanentLinksControl.touched || addLinkControl.touched || addLinkControl.dirty)"
                        translate="reports.permanentLinksRequired"></mat-error>
                    <div *ngFor="let link of permanentLinksControl.value; let index = index;" class="item-container" @fadeExpand
                        [@.disabled]="disableAnimations">
                        <div class="link">
                            <i class="fas fa-link"></i>
                            <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
                            <i class="fas fa-minus-circle" (click)="removeLink(index)" *ngIf="form.enabled"></i>
                        </div>
                    </div>
                    <form (submit)="addLink(); $event.preventDefault();" *ngIf="form.enabled">
                        <mat-form-field class="link-field" subscriptSizing="dynamic">
                            <mat-label>{{ 'reports.addLink' | translate }}</mat-label>
                            <input matInput type="url" name="link" [formControl]="addLinkControl" autocomplete="off"
                                maxlength="600" placeholder="https://example.com" />
                            <button mat-icon-button matSuffix type="submit" color="primary"
                                [appMatVisualDisable]="!addLinkControl.value" [matTooltip]="'Done' | translate">
                                <mat-icon>done</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </fieldset>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.owner' | translate }}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select formControlName="owner" name="owner" [options]="users$ | async"
                            [optionValueFunc]="getUserId" [optionDisplayFunc]="getUserName" required>
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getUserName(originalOwner)" disabled required />
                    }
                    <mat-error *ngIf="ownerControl.hasError('required')"
                        translate="reports.ownerRequired"></mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.updater' | translate }}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select formControlName="updater" name="updater" [options]="users$ | async"
                            [optionValueFunc]="getUserId" [optionDisplayFunc]="getUserName" [required]="!isDelegatedControl.value">
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getUserName(originalUpdater)" disabled [required]="!isDelegatedControl.value" />
                    }
                    <mat-error *ngIf="updaterControl.hasError('required')"
                        translate="reports.updaterRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'reports.department' | translate }}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select formControlName="department" name="department" [options]="departments$ | async"
                            [optionValueFunc]="getDepartmentId" [optionDisplayFunc]="getDepartmentName">
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="originalDepartment?.name" disabled />
                    }
                </mat-form-field>
            </div>

            @if (form.enabled && (categories$ | async); as categories) {
                <!-- If we have a sub-category list, start the category on a new line. -->
                <div class="clearfix" *ngIf="subCategories$ | async"></div>

                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{ 'reports.category' | translate }}</mat-label>
                        <app-auto-select formControlName="category" name="category"
                            [options]="categories" [optionValueFunc]="getCategoryId"
                            [optionDisplayFunc]="getCategoryDisplay">
                        </app-auto-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-6" *ngIf="subCategories$ | async; let subCategories">
                    <mat-form-field>
                        <mat-label>{{ 'reports.subCategory' | translate }}</mat-label>
                        <app-auto-select formControlName="subCategory" name="subCategory"
                            [options]="subCategories" [optionValueFunc]="getSubCategoryId"
                            [optionDisplayFunc]="getSubCategoryDisplay">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            } @else if (form.disabled && originalCategory) {
                <!-- If we have a sub-category list, start the category on a new line. -->
                <div class="clearfix" *ngIf="originalSubCategory"></div>

                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{'reports.category' | translate}}</mat-label>
                        <input matInput type="text" [value]="originalCategory.description" disabled />
                    </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="originalSubCategory">
                    <mat-form-field>
                        <mat-label>{{'reports.subCategory' | translate}}</mat-label>
                        <input matInput type="text" [value]="originalSubCategory.description" disabled required />
                    </mat-form-field>
                </div>
            }
        </div>

        <div class="row">
            <!-- Capture Frequency -->
            <div class="col-sm-6" *ngIf="schedulingVisible$ | async">
                @if (schedulingEnabled$ | async) {
                    <mat-form-field>
                        <mat-label>{{ "updateScheduleType.title" | translate }}</mat-label>
                        <mat-select [formControl]="scheduleTypeControl" name="updateScheduleType" required>
                            <mat-option *ngFor="let t of updateScheduleTypes" [value]="t">
                                {{ getUpdateScheduleTypeNameKey(t) | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                } @else {
                    <app-fake-schedule-field></app-fake-schedule-field>
                }
            </div>

            <div class="col-sm-6" *ngIf="noteEnforcementEnabled$ | async">
                <mat-form-field>
                    <mat-label>{{ 'reports.requireNote' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="requireNote" required>
                        <mat-option *ngFor="let option of requireNoteOptions" [value]="option">
                            {{ getRequireNoteNameKey(option) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <app-recurrence-control *ngIf="hasRecurrence && (schedulingEnabled$ | async)" [formControl]="recurrenceControl"
            name="recurrence" required>
        </app-recurrence-control>

        <div class="row">
            <div class="col-sm-6">
                <mat-checkbox formControlName="isRecurring" name="isRecurring">
                    {{ 'reports.recurringReport' | translate}}
                </mat-checkbox>
            </div>
        </div>
        
        @if (delegationVisible$ | async) {
            <div class="row">
                <div class="col-sm-12">
                    <mat-checkbox formControlName="isDelegated">
                        {{'delegation.delegateToAnotherTeam' | translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="delegation-container" *ngIf="isDelegatedControl.value" @fadeExpand [@.disabled]="disableAnimations">
                <fieldset formGroupName="delegation">
                    <legend>{{'delegation.delegation' | translate}}</legend>
                    <div class="delegation-row">
                        <mat-form-field>
                            <mat-label>{{'delegation.team' | translate}}</mat-label>
                            @if (form.enabled) {
                                <app-auto-select formControlName="team" [options]="delegationTeams$ | async"
                                    [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData" required>
                                    <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                                </app-auto-select>
                            } @else {
                                <input matInput type="text" [value]="originalDelegation?.team?.name" disabled required />
                            }
                            <mat-error *ngIf="!delegationTeamControl.valid"
                                translate="delegation.required"></mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'delegation.assignee' | translate}}</mat-label>
                            @if (form.enabled) {
                                <app-auto-select formControlName="assignee" [options]="delegationUsers$ | async" [optionValueFunc]="getUserId"
                                    [optionDisplayFunc]="getUserName" required>
                                </app-auto-select>
                            } @else {
                                <input matInput type="text" [value]="getUserName(originalDelegation?.assignee)" disabled required />
                            }
                            <mat-error *ngIf="!delegationAssigneeControl.valid"
                                translate="delegation.required"></mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'delegation.responsibility' | translate}}</mat-label>
                            <mat-select formControlName="responsibility">
                                <mat-option *ngFor="let responsibility of delegationResponsibilities" [value]="responsibility">
                                    {{ getDelegationResponsibilityNameKey(responsibility) | translate }}
                                </mat-option>
                            </mat-select>
                            <app-delegation-responsibility-help-indicator matSuffix></app-delegation-responsibility-help-indicator>
                        </mat-form-field>
                    </div>
                </fieldset>
            </div>
        }

        <div class="row" *ngIf="triggeredDiscussionsEnabled$ | async">
            <div class="col-xs-12 trigger-discussion-row">
                <mat-checkbox formControlName="triggerDiscussion">
                    {{ 'reports.triggerDiscussion.label' | translate }}
                </mat-checkbox>
                <mat-form-field class="trigger-discussion-type" subscriptSizing="dynamic">
                    <mat-select disableOptionCentering formControlName="triggerDiscussionType">
                        <mat-option value="always">
                            {{ 'reports.triggerDiscussion.everyWeek' | translate }}
                        </mat-option>
                        <mat-option value="offtarget">
                            {{ 'reports.triggerDiscussion.whenOffTarget' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="triggerDiscussionTypeControl.value === 'offtarget'">
                    <span @fadeIn>{{ 'reports.triggerDiscussion.for' | translate }}</span>
                    <mat-form-field class="trigger-discussion-after" subscriptSizing="dynamic" @fadeIn>
                        <mat-select disableOptionCentering formControlName="triggerDiscussionAfter">
                            <mat-option *ngFor="let weeks of triggerDiscussionAfterUpdatesOptions" [value]="weeks" [ngSwitch]="weeks">
                                <ng-container *ngSwitchCase="1">
                                    {{ 'reports.triggerDiscussion.week' | translate: { weeks } }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ 'reports.triggerDiscussion.weeks' | translate: { weeks } }}
                                </ng-container>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>

        <div class="row" *ngIf="form.errors">
            <div class="col-xs-12" role="alert">
                <p class="warning-box" *ngIf="form.errors?.capExceeded" translate="reports.capExceeded"></p>
                <p class="warning-box" *ngIf="form.errors?.capReached" translate="reports.capReached"></p>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <app-status-button type="submit" [state]="buttonState" [disabled]="form.disabled">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>