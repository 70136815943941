import { trigger } from "@angular/animations";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CategoryDetailDto, CurrentCompanyDto, CurrentTeamDto, GetReportDto, GetTeamUserResponsibilityDelegationDto, PlanReportsApi, RecurrenceDto, RequireNote, SimpleCategoryDto, SimpleCompanyDto, SimpleCompanyTeamDto, SimpleDepartmentDto, SimpleSubCategoryDto, SimpleTeamDto, SimpleUserDto, UpdateReportDto, UpdateTeamUserResponsibilityDelegationDto } from "@api";
import { combineLatest, defer, distinctUntilChanged, filter, identity, map, Observable, of, startWith, Subscription, switchMap, tap } from "rxjs";

import { CategoryRepository, DepartmentRepository, IQuarter, TeamRepository, TeamSettingsRepository, UserRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { DelegationResponsibility, PlanningStatus, ReportCollectionType, UpdateScheduleType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeExpandAnimationBuilder, fadeInAnimationBuilder } from "~shared/util/animations";
import { LINK_PATTERN } from "~shared/util/custom-validators";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isDelegationEnabled, isFlexibleSchedulingEnabled, isFlexibleSchedulingTeased, isNoteEnforcementEnabled, isTriggeredDiscussionsEnabled } from "~shared/util/feature-helper";
import { setEnabledState } from "~shared/util/form-helper";
import { shareReplayUntil, tapFirst } from "~shared/util/rx-operators";
import { compareTeams, getTeamSearchData } from "~shared/util/team-helper";
import { getDelegationResponsibilityNameKey, getPlanningStatusNameKey, getReportCollectionTypeNameKey, getRequireNoteNameKey, getUpdateScheduleTypeNameKey } from "~shared/util/translation-helper";
import { bindTriggeredDiscussionValue, DEFAULT_TRIGGER_AFTER_UPDATES, getTriggeredDiscussionValue, TRIGGER_AFTER_UPDATES_OPTIONS, TriggeredDiscussionType } from "~shared/util/triggered-discussion-helper";
import { getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";


interface IReportDialogData {
    report?: GetReportDto;
    isCopy?: boolean;
    team: SimpleCompanyTeamDto;
    financialYear: number;
    planningPeriod: number;
    readonly: boolean;
}

export interface IAddReportDialogData {
    company: CurrentCompanyDto | SimpleCompanyDto;
    team: CurrentTeamDto | SimpleTeamDto;
    financialYear: number;
    planningPeriod: number;
}

@Component({
    selector: "app-edit-report-dialog",
    templateUrl: "./edit-report-dialog.component.html",
    styleUrls: ["./edit-report-dialog.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
        trigger("fadeExpand", fadeExpandAnimationBuilder()),
    ],
    standalone: false,
})
export class EditReportDialogComponent extends WithDestroy() implements OnInit, OnDestroy, AfterViewInit {

    buttonState: ButtonState;
    disableAnimations = true;

    readonly planningStatuses = [
        PlanningStatus.draft,
        PlanningStatus.locked,
    ];

    readonly collectionTypes = [
        ReportCollectionType.standard,
        ReportCollectionType.externallyUpdated,
    ];

    readonly updateScheduleTypes = [
        UpdateScheduleType.everyPeriod,
        UpdateScheduleType.everyMeeting,
        UpdateScheduleType.custom,
    ];

    readonly requireNoteOptions = [
        RequireNote.never,
        RequireNote.always,
    ];

    readonly delegationResponsibilities = [
        DelegationResponsibility.ownership,
        DelegationResponsibility.update,
    ];

    readonly triggerDiscussionAfterUpdatesOptions = TRIGGER_AFTER_UPDATES_OPTIONS;

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly users$: Observable<SimpleUserDto[]>;
    readonly departments$: Observable<SimpleDepartmentDto[]>;
    readonly categories$: Observable<CategoryDetailDto[] | null>;
    readonly subCategories$: Observable<SimpleSubCategoryDto[] | null>;
    readonly delegationTeams$: Observable<SimpleCompanyTeamDto[]>;
    readonly delegationUsers$: Observable<SimpleUserDto[]>;

    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly reportStatusControl = this.fb.nonNullable.control(PlanningStatus.draft, [Validators.required]);
    readonly collectionTypeControl = this.fb.nonNullable.control(ReportCollectionType.standard, [Validators.required]);
    readonly permanentLinksControl = this.fb.nonNullable.control<string[]>({
        value: [],
        disabled: true,
    }, [Validators.required]);

    readonly ownerControl = this.fb.nonNullable.control<string | null>(null, [Validators.required]);
    readonly updaterControl = this.fb.nonNullable.control<string | null>(null, [Validators.required]);
    readonly teamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly quarterControl = this.fb.control<IQuarter | null>(null, [Validators.required]);
    readonly departmentControl = this.fb.nonNullable.control<string | null>(null);
    readonly categoryControl = this.fb.nonNullable.control<string | null>(null);
    readonly subCategoryControl = this.fb.nonNullable.control<string | null>(null);
    readonly isRecurringControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionTypeControl = this.fb.nonNullable.control<TriggeredDiscussionType>("offtarget");
    readonly triggerDiscussionAfterControl = this.fb.nonNullable.control<number>(DEFAULT_TRIGGER_AFTER_UPDATES);

    readonly scheduleTypeControl = this.fb.nonNullable.control(UpdateScheduleType.everyPeriod, [Validators.required]);
    readonly recurrenceControl = this.fb.nonNullable.control<RecurrenceDto | undefined>(undefined);

    readonly captureFrequencyControl = this.fb.group({
        type: this.scheduleTypeControl,
        recurrence: this.recurrenceControl
    });

    readonly requireNoteControl = this.fb.nonNullable.control(RequireNote.never, [Validators.required]);

    readonly isDelegatedControl = this.fb.nonNullable.control(false);
    readonly delegationTeamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly delegationAssigneeControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly delegationResponsibilityControl = this.fb.nonNullable.control(DelegationResponsibility.ownership);
    readonly delegationControl = this.fb.group({
        team: this.delegationTeamControl,
        assignee: this.delegationAssigneeControl,
        responsibility: this.delegationResponsibilityControl,
    });

    readonly form = this.fb.group({
        description: this.descriptionControl,
        reportStatus: this.reportStatusControl,
        collectionType: this.collectionTypeControl,
        permanentLinks: this.permanentLinksControl,
        owner: this.ownerControl,
        updater: this.updaterControl,
        team: this.teamControl,
        quarter: this.quarterControl,
        department: this.departmentControl,
        category: this.categoryControl,
        subCategory: this.subCategoryControl,
        isRecurring: this.isRecurringControl,
        triggerDiscussion: this.triggerDiscussionControl,
        triggerDiscussionType: this.triggerDiscussionTypeControl,
        triggerDiscussionAfter: this.triggerDiscussionAfterControl,
        captureFrequency: this.captureFrequencyControl,
        requireNote: this.requireNoteControl,

        isDelegated: this.isDelegatedControl,
        delegation: this.delegationControl,
    });

    readonly addLinkControl = this.fb.control<string | null>(null);

    readonly getUserName = getUserName;
    readonly getPlanningStatusNameKey = getPlanningStatusNameKey;
    readonly getCollectionTypeNameKey = getReportCollectionTypeNameKey;
    readonly getUpdateScheduleTypeNameKey = getUpdateScheduleTypeNameKey;
    readonly getRequireNoteNameKey = getRequireNoteNameKey;
    readonly getDelegationResponsibilityNameKey = getDelegationResponsibilityNameKey;
    readonly compareTeams = compareTeams;
    readonly getTeamSearchData = getTeamSearchData;

    get hasPermanentLinks(): boolean {
        return this.collectionTypeControl.value === ReportCollectionType.externallyUpdated;
    }

    get hasRecurrence(): boolean {
        return this.scheduleTypeControl.value === UpdateScheduleType.custom;
    }

    get originalOwner(): SimpleUserDto | undefined {
        return this.report?.owner;
    }

    get originalUpdater(): SimpleUserDto | undefined {
        return this.report?.owner;
    }

    get originalDepartment(): SimpleDepartmentDto | undefined {
        return this.report?.department;
    }

    get originalCategory(): SimpleCategoryDto | undefined {
        return this.report?.category;
    }

    get originalSubCategory(): SimpleSubCategoryDto | undefined {
        return this.report?.category?.subCategory;
    }

    get originalDelegation(): GetTeamUserResponsibilityDelegationDto | undefined {
        return this.report?.delegation;
    }

    readonly schedulingVisible$: Observable<boolean>;
    readonly schedulingEnabled$: Observable<boolean>;
    readonly noteEnforcementEnabled$: Observable<boolean>;
    readonly delegationVisible$: Observable<boolean>;
    readonly triggeredDiscussionsEnabled$: Observable<boolean>;

    private readonly report?: GetReportDto;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planReportsApi: PlanReportsApi,
        private readonly teamRepository: TeamRepository,
        private readonly userRepository: UserRepository,
        private readonly departmentRepository: DepartmentRepository,
        private readonly categoryRepository: CategoryRepository,
        private readonly teamSettingsRepository: TeamSettingsRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly dialogRef: MatDialogRef<EditReportDialogComponent, GetReportDto>,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: IReportDialogData,
    ) {
        super();
        this.teamControl.setValue(data.team);
        this.report = data.isCopy ? undefined : data.report;
        this.quarterControl.setValue({
            financialYear: data.financialYear,
            quarter: data.planningPeriod,
        });

        if (this.report) {
            if (data.readonly || this.report.planningStatus === PlanningStatus.locked || this.report.isDelegated) {
                this.form.disable();
            } else {
                this.teamControl.disable();
                this.quarterControl.disable();
            }
        } else if (!this.teamContext.features.crossTeamFeaturesEnabled()) {
            this.teamControl.disable();
        }

        const companyTeamId$ = valueAndChanges(this.teamControl).pipe(
            filter(Boolean),
            map((team) => ({ companyId: team.company.id, teamId: team.id })),
            distinctUntilChanged((left, right) => left.companyId === right.companyId && left.teamId === right.teamId),
        );
        const companyId$ = companyTeamId$.pipe(
            map(({ companyId }) => companyId),
            distinctUntilChanged(),
        );

        this.teams$ = defer(() => this.teamRepository.getClientInstanceTeams(data.team.company.id));

        this.users$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.userRepository.getTeamMembers(companyId, teamId)),
            // If we are creating a new report (and not copying), choose a default owner/updater after loading team members.
            data.readonly ? identity : tapFirst(this.setDefaultOwnerUpdater),
            tap(this.availableUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.departments$ = companyId$.pipe(
            switchMap(companyId => this.departmentRepository.getDepartments(companyId)),
            tap(this.availableDepartmentsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.categories$ = companyId$.pipe(
            switchMap(companyId => this.categoryRepository.getCategories(companyId)),
            tap(this.availableCategoriesChanged),
            // As we hide the categories field if we don't have any categories, we set to null to simplify view logic.
            map(categories => !categories.length ? null : categories),
            shareReplayUntil(this.destroyed$),
        );
        this.subCategories$ = combineLatest({
            categories: this.categories$,
            categoryId: valueAndChanges(this.categoryControl),
        }).pipe(
            map(({ categories, categoryId }) => categories?.find(c => c.id === categoryId)?.subCategories ?? []),
            tap(this.availableSubCategoriesChanged),
            // As we hide the subcategories field if we don't have any subcategories, we set to null to simplify view logic.
            map(subCategories => !subCategories.length ? null : subCategories),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationTeams$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.teamSettingsRepository.getDelegationTeams(companyId, teamId)),
            tap(this.availableDelegatedTeamsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationUsers$ = valueAndChanges(this.delegationTeamControl).pipe(
            switchMap(team => !team ? of([]) : this.userRepository.getTeamMembers(team.company.id, team.id)),
            tap(this.availableDelegatedUsersChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.schedulingVisible$ = this.teamContext.companyTeam$.pipe(
            map(ct => isFlexibleSchedulingEnabled(ct) || isFlexibleSchedulingTeased(ct)),
        );
        this.schedulingEnabled$ = this.teamContext.companyTeam$.pipe(map(isFlexibleSchedulingEnabled));

        this.noteEnforcementEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isNoteEnforcementEnabled),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationVisible$ = this.teamContext.companyTeam$.pipe(
            map(isDelegationEnabled),
            switchMap(enabled => {
                // If the form is disabled, show the delegation section if enabled and set but do no further processing
                if (this.form.disabled) return of(enabled && !!this.report?.delegation);
                // Delegation is otherwise visible if enabled and there are some delegation teams set
                const visible$ = !enabled ? of(false) : this.delegationTeams$.pipe(map(teams => !!teams.length));
                return visible$.pipe(
                    tap(visible => {
                        if (!visible) {
                            this.isDelegatedControl.setValue(false);
                            this.isDelegatedControl.disable();
                            this.delegationControl.disable();
                        } else {
                            if (this.form.enabled) {
                                this.isDelegatedControl.enable();
                            }
                        }
                    }),
                    // While the delegation teams are loading, should be based on whether delegation settings currently exist.
                    startWith(!!this.report?.delegation),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.triggeredDiscussionsEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isTriggeredDiscussionsEnabled),
            shareReplayUntil(this.destroyed$),
        );

        if (data.report) this.bindReport(data.report);
    }

    static openForAdd(dialog: MatDialog, data: IAddReportDialogData) {
        return this.openInternal(dialog, {
            report: undefined,
            team: {
                id: data.team.id,
                name: data.team.name,
                company: {
                    id: data.company.id,
                    name: data.company.name,
                    clientId: data.company.clientId,
                },
            },
            financialYear: data.financialYear,
            planningPeriod: data.planningPeriod,
            isCopy: false,
            readonly: false
        });
    }

    static openForEdit(dialog: MatDialog, report: GetReportDto, isCopy = false, readonly = false) {
        const { company, team } = isCopy ? getDelegatedItemCompanyTeam(report) : report;
        return this.openInternal(dialog, {
            report,
            team: {
                id: team.id,
                name: team.name,
                company: {
                    id: company.id,
                    name: company.name,
                    clientId: company.clientId,
                },
            },
            financialYear: report.financialYear,
            planningPeriod: report.planningPeriod,
            isCopy,
            readonly
        });
    }

    private static openInternal(dialog: MatDialog, data: IReportDialogData) {
        return dialog.open<EditReportDialogComponent, IReportDialogData, GetReportDto>(EditReportDialogComponent, {
            width: "750px",
            data: data
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(valueAndChanges(this.collectionTypeControl).subscribe(collectionType => {
            if (collectionType === ReportCollectionType.standard) {
                this.permanentLinksControl.disable();
            } else {
                if (this.form.enabled) {
                    this.permanentLinksControl.enable();
                }
            }
        }));
        this.subscriptions.add(valueAndChanges(this.isDelegatedControl).subscribe(isDelegated => {
            if (!isDelegated) {
                this.delegationControl.disable();
                this.delegationTeamControl.setValue(null);
                this.delegationAssigneeControl.setValue(null);
                // When delegating the report, we don't need to specify an updater
                this.updaterControl.setValidators([Validators.required]);
            } else {
                this.updaterControl.setValidators([]);
                if (this.form.enabled) {
                    this.delegationControl.enable();
                }
            }
            this.updaterControl.updateValueAndValidity();
        }));
        this.subscriptions.add(valueAndChanges(this.triggerDiscussionControl).subscribe(this.handleTriggerDiscussionChange));
        this.subscriptions.add(valueAndChanges(this.triggerDiscussionTypeControl).subscribe(this.handleTriggerDiscussionTypeChange));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    addLink = () => {
        let linkValue = this.addLinkControl.value;
        if (!this.addLinkControl.valid || !linkValue || !this.form.enabled) return;

        if (!LINK_PATTERN.test(linkValue)) linkValue = "http://" + linkValue;

        const links = this.permanentLinksControl.value;
        this.permanentLinksControl.setValue([...links, linkValue]);
        this.permanentLinksControl.markAsDirty();
        this.permanentLinksControl.markAsTouched();
        this.addLinkControl.reset();
    };

    removeLink = (index: number) => {
        if (!this.form.enabled) return;
        const links = [...this.permanentLinksControl.value];
        links.splice(index, 1);
        this.permanentLinksControl.setValue(links);
        this.permanentLinksControl.markAsDirty();
        this.permanentLinksControl.markAsTouched();
    };

    save() {
        if (!this.form.valid || this.buttonState) return;
        this.buttonState = "loading";

        const dto: UpdateReportDto = {
            description: this.descriptionControl.value ?? "",
            reportStatus: this.reportStatusControl.value,
            collectionType: this.collectionTypeControl.value,
            permanentLinks: this.collectionTypeControl.value === ReportCollectionType.externallyUpdated ?
                this.permanentLinksControl.value : undefined,

            ownerUserId: this.ownerControl.value ?? "",
            updaterUserId: this.updaterControl.value ?? undefined,
            departmentId: this.departmentControl.value ?? undefined,
            categoryId: this.categoryControl.value ?? undefined,
            subCategoryId: this.subCategoryControl.value ?? undefined,

            isRecurring: this.isRecurringControl.value,
            requireNote: this.requireNoteControl.value,
            detectProblemAfterUpdates: getTriggeredDiscussionValue(this.form.controls),

            scheduleDefinition: this.captureFrequencyControl.value,

            delegation: this.getDelegation(),
        };

        let obs$: Observable<GetReportDto>;
        if (this.report) {
            obs$ = this.planReportsApi.updateReport(
                this.report.company.id,
                this.report.team.id,
                toFiscalQuarter({ financialYear: this.report.financialYear, quarter: this.report.planningPeriod }),
                this.report.id,
                dto,
            );
        } else {
            const team = this.teamControl.value;
            obs$ = this.planReportsApi.addReport(
                team?.company.id ?? "",
                team?.id ?? "",
                toFiscalQuarter(this.quarterControl.value as IQuarter),
                dto,
            );
        }

        obs$.pipe(wrapWorkfactaError()).subscribe({
            next: (report) => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(report);
                }, 1000);
            },
            error: (error) => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                if (error instanceof WorkfactaError) {
                    switch (error.code) {
                        case ErrorCode.planCapReached:
                            this.form.setErrors({ capReached: true });
                            return;
                        case ErrorCode.planCapExceeded:
                            this.form.setErrors({ capExceeded: true });
                            return;
                    }
                }
                this.notificationService.errorUnexpected();
            }
        });
    }

    getUserId = (user: SimpleUserDto) => user.userId;
    getDepartmentId = (department: SimpleDepartmentDto | null | undefined): string => department?.id ?? "";
    getDepartmentName = (department: SimpleDepartmentDto | null | undefined): string => department?.name ?? "";
    getCategoryId = (category: CategoryDetailDto): string => category?.id ?? "";
    getCategoryDisplay = (category: CategoryDetailDto | null | undefined) => category?.description ?? "";
    getSubCategoryId = (subCategory: SimpleSubCategoryDto): string => subCategory?.id ?? "";
    getSubCategoryDisplay = (subCategory: SimpleSubCategoryDto | null | undefined): string => subCategory?.description ?? "";
    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined): string => team?.name ?? "";

    private bindReport = (report: GetReportDto) => {
        this.descriptionControl.setValue(report.description);
        this.reportStatusControl.setValue(report.reportStatus);
        this.collectionTypeControl.setValue(report.collectionType);
        this.permanentLinksControl.setValue([...report.permanentLinks ?? []]);
        this.ownerControl.setValue(report.owner?.userId ?? null);
        this.updaterControl.setValue(report.updater?.userId ?? null);
        this.quarterControl.setValue({
            financialYear: report.financialYear,
            quarter: report.planningPeriod,
        });
        this.departmentControl.setValue(report.department?.id ?? null);
        this.categoryControl.setValue(report.category?.id ?? null);
        this.subCategoryControl.setValue(report.category?.subCategory?.id ?? null);
        this.isRecurringControl.setValue(report.isRecurring);
        bindTriggeredDiscussionValue(this.form.controls, report.detectProblemAfterUpdates);

        this.scheduleTypeControl.setValue(report.scheduleDefinition.type ?? UpdateScheduleType.everyPeriod);
        this.recurrenceControl.setValue(report.scheduleDefinition.recurrence);
        this.requireNoteControl.setValue(report.requireNote);

        if (report.delegation) this.bindDelegation(report.delegation);

        if (!report.canEdit) {
            this.form.disable();
            this.form.setErrors({ capExceeded: true });
        }
    };

    private bindDelegation = (delegation: GetTeamUserResponsibilityDelegationDto) => {
        this.isDelegatedControl.setValue(true);
        this.delegationTeamControl.setValue({
            ...delegation.team,
            company: delegation.company,
        });
        this.delegationAssigneeControl.setValue(delegation.assignee?.userId ?? null);
        this.delegationResponsibilityControl.setValue(delegation.responsibility);
    };

    private getDelegation = (): UpdateTeamUserResponsibilityDelegationDto | undefined => {
        if (!this.isDelegatedControl.value) return undefined;
        const team = this.delegationTeamControl.value;
        const assignee = this.delegationAssigneeControl.value;
        if (!team || !assignee) return undefined;
        return {
            companyId: team.company.id,
            teamId: team.id,
            assigneeUserId: assignee,
            responsibility: this.delegationResponsibilityControl.value,
        };
    };

    private setDefaultOwnerUpdater = (users: SimpleUserDto[]): void => {
        // Ensure the number is a new number and the owner is not yet set.
        if (this.report || this.ownerControl.value) return;

        const currentUserId = this.userContext.userId();
        // Ensure the user is in the current team.
        if (!users.some(u => u.userId === currentUserId)) return;

        this.ownerControl.setValue(currentUserId);
        this.updaterControl.setValue(currentUserId);
    };

    private availableUsersChanged = (users: SimpleUserDto[]) => {
        if (this.ownerControl.value && !users.some(x => x.userId === this.ownerControl.value)) {
            this.ownerControl.setValue(null);
        }
        if (this.updaterControl.value && !users.some(x => x.userId === this.updaterControl.value)) {
            this.updaterControl.setValue(null);
        }
    };

    private availableDepartmentsChanged = (departments: SimpleDepartmentDto[]) => {
        if (this.departmentControl.value && !departments.some(d => d.id === this.departmentControl.value)) {
            this.departmentControl.setValue(null);
        }
    };

    private availableCategoriesChanged = (categories: CategoryDetailDto[]) => {
        if (!categories.length) {
            // Ensures even if the list of sub-categories is not subscribed to (due to no categories)
            // the control will be cleared and disabled appropriately.
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
        }
        if (this.categoryControl.value && !categories.some(x => x.id === this.categoryControl.value)) {
            this.categoryControl.setValue(null);
        }
    };

    private availableSubCategoriesChanged = (subCategories: SimpleSubCategoryDto[]) => {
        if (!subCategories.length) {
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
            return;
        }

        if (this.form.enabled) this.subCategoryControl.enable();
        if (this.subCategoryControl.value && !subCategories.some(x => x.id === this.subCategoryControl.value)) {
            this.subCategoryControl.setValue(null);
        }
    };

    private availableDelegatedTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        const currentTeam = this.delegationTeamControl.value;
        if (currentTeam && !teams.some(t => compareTeams(t, currentTeam))) {
            this.delegationTeamControl.setValue(null);
        }
    };

    private availableDelegatedUsersChanged = (users: SimpleUserDto[]) => {
        if (this.delegationAssigneeControl.value && !users.some(x => x.userId === this.delegationAssigneeControl.value)) {
            this.delegationAssigneeControl.setValue(null);
        }
    };

    private handleTriggerDiscussionChange = (enabled: boolean) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionTypeControl, enabled);
        setEnabledState(this.triggerDiscussionAfterControl, enabled && this.triggerDiscussionTypeControl.value === "offtarget");
    };

    private handleTriggerDiscussionTypeChange = (type: TriggeredDiscussionType) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionAfterControl, type === "offtarget" && this.triggerDiscussionControl.value);
    };
}
