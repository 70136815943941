/* eslint-disable max-classes-per-file */
import { catchError, defer, delay, EMPTY, filter, finalize, first, map, merge, mergeMap, Observable, of, share, Subject, takeUntil, timer } from "rxjs";

import { Filter } from "./comparators";
import { StateEvent } from "./state-shared";

type AugmentedStateEvent<TItem, TReference> = StateEvent<TItem, TReference> & {
    reference: TReference;
};

export interface StateService<TItem, TReference> {
    events$: Observable<StateEvent<TItem, TReference>>;

    notifyAdd(item: TItem): void;
    notifyUpdate(item: TItem): void;
    notifyDelete(item: TReference): void;
}

export interface StateServiceWithRefresh<TItem, TReference, TRefresh = TItem | TReference>
    extends StateService<TItem, TReference> {
    notifyRefresh(item: TRefresh): void;

    remoteAdd(item: TReference): void;
    remoteUpdate(item: TReference): void;
    remoteDelete(item: TReference): void;
}

export abstract class BaseStateService<TItem, TReference> implements
    StateService<TItem, TReference> {

    abstract readonly events$: Observable<StateEvent<TItem, TReference>>;

    protected readonly eventsSubject = new Subject<StateEvent<TItem, TReference>>();

    constructor() { }

    notifyAdd = (item: TItem) => this.eventsSubject.next({ type: "added", item, remote: false, });
    notifyUpdate = (item: TItem) => this.eventsSubject.next({ type: "updated", item, remote: false });
    notifyDelete = (item: TReference) => this.eventsSubject.next({ type: "deleted", item, remote: false });
}

export interface RefreshEvent<TReference> {
    readonly type: "added" | "updated";
    readonly reference: TReference;
    readonly remote: boolean;
};

const SERVER_EVENT_DEBOUNCE_MS = 2000;
const LOCAL_EVENT_DEBOUNCE_MS = 50;

export abstract class BaseStateServiceWithRefresh<TItem, TReference, TRefresh = TItem | TReference>
    extends BaseStateService<TItem, TReference> implements StateServiceWithRefresh<TItem, TReference, TRefresh> {

    readonly events$: Observable<StateEvent<TItem, TReference>>;

    protected readonly localEvents$ = this.eventsSubject.pipe(
        filter((e): e is StateEvent<TItem, TReference> & { remote: false } => !e.remote),
    );

    protected readonly refreshItemSubject = new Subject<RefreshEvent<TReference>>();
    protected readonly refreshedItems$ = this.refreshItemSubject.pipe(
        map(({ type, reference, remote }) => ({
            type,
            reference,
            remote,
            item$: defer(() => {
                if (this.isRefreshing(reference)) return EMPTY;
                const refreshReference = this.getRefreshReference(reference);
                this.refreshingItems.add(refreshReference);
                return this.refreshItem(refreshReference).pipe(
                    finalize(() => this.refreshingItems.delete(refreshReference)),
                    catchError(() => EMPTY),
                );
            }).pipe(
                share(),
            ),
        })),
        share(),
    );

    protected readonly refreshingItems = new Set<TReference>();

    constructor() {
        super();

        this.events$ = this.eventsForFilter(() => true, () => true).pipe(
            share(),
        );
    }

    protected abstract refreshItem(item: TReference): Observable<TItem>;
    protected abstract toReference(item: TRefresh | TItem): TReference;
    protected abstract compareReferences(ref1: TReference, ref2: TReference): boolean;

    notifyRefresh = (item: TRefresh): void => this.refreshItemSubject.next(
        { type: "updated", reference: this.toReference(item), remote: false });

    remoteAdd = (item: TReference): void => this.refreshItemSubject.next({ type: "added", reference: item, remote: true });
    remoteUpdate = (item: TReference): void => this.refreshItemSubject.next({ type: "updated", reference: item, remote: true });
    remoteDelete = (item: TReference): void => this.eventsSubject.next({ type: "deleted", item, remote: true });

    protected isRefreshing = (item: TReference) => {
        for (const refreshingItem of this.refreshingItems) {
            if (this.compareReferences(refreshingItem, item)) return true;
        }
        return false;
    };

    protected getRefreshReference = (item: TReference): TReference => item;

    protected eventsForItems = (items: (TRefresh | TItem)[]): Observable<StateEvent<TItem, TReference>> => {
        const references = items.map(item => this.toReference(item));
        return this.eventsForFilter(
            item => {
                const reference = this.toReference(item);
                return references.some(i => this.compareReferences(i, reference));
            },
            reference => references.some(r => this.compareReferences(r, reference)),
            // We swallow the refresh if every item either is not covered by the refresh or the local event satisfies the item.
            (refresh, localEvent) => references.every(r =>
                !this.compareReferences(r, refresh) ||
                this.compareReferences(r, localEvent.reference)),
        );
    };

    /**
     * Gets all events for the provided filter.
     *
     * @param itemFilter The filter to use for the directly emitted items
     * @param refFilter The filter to use for any refs that are refreshed
     * @returns An observable containing all events for the provided filters.
     */
    protected eventsForFilter = (
        itemFilter: Filter<TItem>,
        refFilter: Filter<TReference>,
        shouldSuppressRefresh: (refresh: TReference, localEvent: AugmentedStateEvent<TItem, TReference>) => boolean = () => true,
    ): Observable<StateEvent<TItem, TReference>> => merge(
        this.eventsSubject.pipe(
            // A deleted event contains a reference, not an item. As such, we use the reference filter for those events.
            filter(event => event.type === "deleted" ? refFilter(event.item) : itemFilter(event.item)),
        ),
        this.refreshedItems$.pipe(
            filter(item => refFilter(item.reference)),
            mergeMap(item => (!item.remote ?
                // A local refresh should be triggered immediately.
                of(null) :
                // When we are notified of a remote update, we don't want to refresh immediately. This is because
                // this event may have been caused by some local action, and we want to wait for the event to be locally emitted
                // to avoid an unnecessary refresh.
                merge(
                    // We either wait for the server debounce time
                    timer(SERVER_EVENT_DEBOUNCE_MS),
                    // or once any local event has been emitted
                    this.localEvents$.pipe(
                        // (plus a slight delay, to ensure any other simultaneous local events are notified).
                        delay(LOCAL_EVENT_DEBOUNCE_MS),
                    ),
                ).pipe(
                    // whichever is first.
                    first(),
                    map(() => null),
                )
            ).pipe(
                // Once a local event is emitted, we no longer need to load data from the server.
                // eslint-disable-next-line rxjs/no-unsafe-takeuntil
                takeUntil(this.eventsSubject.pipe(
                    filter(event => event.type !== "deleted"),
                    map(event => ({ ...event, reference: this.toReference(event.item) })),
                    // Note that we ensure the event matches the current filter and is for the item we're refreshing.
                    // This is to prevent a specific event from causing a more broad refresh to be skipped
                    // when some subscribers are interested in the broad event.
                    filter(event =>
                        this.compareReferences(event.reference, item.reference) &&
                        shouldSuppressRefresh(item.reference, event)),
                )),
                map(() => item),
            )),
            mergeMap(({ item$, type }) => item$.pipe(
                map(item => ({ type, item, remote: true } as const)),
            )),
            filter(event => itemFilter(event.item)),
        ),
    );
}
