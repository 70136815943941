import { Injectable } from "@angular/core";
import { GetGoalDto, GoalRecordDetailDto, PlanGoalsApi } from "@api";
import { concatMap, from, Observable } from "rxjs";

import { toFiscalQuarter } from "~shared/commonfunctions";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";

import { BaseStateServiceWithRefresh } from "./base-state.service";
import { PeriodicItemReference } from "./comparators";
import { StateEvent } from "./state-shared";

export type GoalReference = PeriodicItemReference;

export type GoalStateEvent = StateEvent<GoalRecordDetailDto, GoalReference>;

@Injectable({
    providedIn: "root",
})
export class GoalStateService extends BaseStateServiceWithRefresh<GoalRecordDetailDto, GoalReference,
    GetGoalDto | GoalRecordDetailDto | GoalReference> {

    constructor(private readonly planGoalsApi: PlanGoalsApi) {
        super();
    }

    eventsForGoals = (...goals: (GetGoalDto | GoalRecordDetailDto)[]) => this.eventsForItems(goals);

    protected refreshItem = (item: GoalReference): Observable<GoalRecordDetailDto> =>
        this.planGoalsApi.getGoalRecords(
            item.companyId,
            item.teamId,
            toFiscalQuarter({ financialYear: item.financialYear, quarter: item.planningPeriod }),
            item.id,
        ).pipe(
            concatMap(records => from(records)),
        );

    protected toReference = (item: GetGoalDto | GoalRecordDetailDto | GoalReference): GoalReference => {
        if ("companyId" in item) return item;
        const { company, team } = getDelegatedItemCompanyTeam(item);
        return {
            companyId: company.id,
            teamId: team.id,
            financialYear: item.financialYear,
            planningPeriod: item.planningPeriod,
            id: item.id,
            collectionPeriod: "week" in item ? item.week : undefined,
        };
    };

    protected getRefreshReference = (item: GoalReference): GoalReference => ({
        ...item,
        // As we refresh all records for a goal at once, our reference should ignore the collection period.
        // This ensures that we don't get double-up on refresh requests.
        collectionPeriod: undefined,
    });

    protected compareReferences = (ref1: GoalReference, ref2: GoalReference): boolean =>
        ref1.companyId === ref2.companyId &&
        ref1.teamId === ref2.teamId &&
        ref1.financialYear === ref2.financialYear &&
        ref1.planningPeriod === ref2.planningPeriod &&
        ref1.id === ref2.id &&
        (ref1.collectionPeriod == null || ref2.collectionPeriod == null || ref1.collectionPeriod === ref2.collectionPeriod);
}
