<table mat-table class="wf-table" [dataSource]="dataSource" matSort [matSortActive]="defaultSort ?? ''" matSortDirection="asc"
    [matSortDisableClear]="!!defaultSort" [trackBy]="trackByIdAndWeek" multiTemplateDataRows wfTableFocus>
    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef>
            <wf-expand-button [expanded]="canExpandAll && areAllExpanded"
                (expand)="expandAll()" (collapse)="collapseAll()"
                [disabled]="!canExpandAll"
                [expandTooltip]="'expandAll' | translate"
                [collapseTooltip]="'collapseAll' | translate">
            </wf-expand-button>
        </th>
        <td mat-cell *matCellDef="let element">
            <wf-expand-button *ngIf="element.actionsCount" [expanded]="isExpanded(element)"
                (expand)="expand(element)" (collapse)="collapse(element)"
                [expandTooltip]="'expandItem' | translate: { item: element.description }"
                [collapseTooltip]="'collapseItem' | translate: { item: element.description }">
            </wf-expand-button>
        </td>
    </ng-container>

    <ng-container matColumnDef="actionsCount">
        <th mat-header-cell *matHeaderCellDef>
            {{'numbers.actionsCount' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <wf-child-count [count]="element.actionsCount"></wf-child-count>
        </td>
    </ng-container>

    <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef>
            {{'numbers.updated' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-completion-indicator [completed]="isUpdated(element)">
            </app-completion-indicator>
        </td>
    </ng-container>

    <ng-container matColumnDef="trend">
        <th mat-header-cell *matHeaderCellDef>
            {{'numbers.trend' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="{{'numbers.trendGraph' | translate}}"
                (click)="openTrend(element)">
                <mat-icon>trending_up</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'numbers.description' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
            <a (click)="viewNumber(element)" wfEmptyLink>
                <app-number-description-column [number]="element"></app-number-description-column>
            </a>
            <app-notes-callout *ngIf="element.notes" [notes]="element.notes"></app-notes-callout>
        </td>
    </ng-container>

    <ng-container matColumnDef="updateDayTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'numbers.dailyUpdates.updateDay' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
            <a (click)="viewNumber(element)" wfEmptyLink>
                {{ getDayOfWeekNameKey(element.updateDay) | translate }}
            </a>
            <app-notes-callout *ngIf="element.notes" [notes]="element.notes"></app-notes-callout>
        </td>
    </ng-container>

    <ng-container matColumnDef="teamTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'numbers.team' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
            <a (click)="viewNumber(element)" wfEmptyLink>
                <app-team-display [team]="element.team" [company]="element.company"></app-team-display>
            </a>
            <app-notes-callout *ngIf="element.notes" [notes]="element.notes"></app-notes-callout>
        </td>
    </ng-container>

    <ng-container matColumnDef="week">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'period.week' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ 'period.weekDisplayShort' | translate: { financialYear: element.financialYear % 100, quarter: element.planningPeriod, week: element.week } }}
        </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
            <app-table-user-filter (selected)="applyOwnerFilter($event)" withSortHeader>
                {{ 'numbers.owner' | translate }}
            </app-table-user-filter>
        </th>
        <td mat-cell *matCellDef="let element">
            <app-delegated-user-display [entity]="element">
                {{ getUserName(element.owner) }}
                <wf-private-indicator *ngIf="element.isPrivate"></wf-private-indicator>
            </app-delegated-user-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="updater">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'numbers.updater' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-delegated-user-display [entity]="element" mode="updater" *ngIf="allowsUpdater(element)">
                {{ getUserName(element.updater) }}
            </app-delegated-user-display>
            <wf-private-indicator *ngIf="element.isPrivate && (isOwnerDisplayed$ | async) !== true"></wf-private-indicator>
        </td>
    </ng-container>

    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'numbers.team' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-team-display [team]="element.team" [company]="element.company"></app-team-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.mat-column-result-display]="readonly"
            [arrowPosition]="readonly ? 'before' : 'after'">
            <ng-container *ngIf="!readonly">
                {{'numbers.result' | translate}}
            </ng-container>
            <ng-container *ngIf="readonly">
                {{'numbers.weeklyResult' | translate}}
            </ng-container>
        </th>
        <td mat-cell *matCellDef="let element" [class.mat-column-result-display]="readonly">
            @if (!readonly) {
                <app-update-number-result [record]="element" (updated)="numberUpdated(element)" [disabled]="disabled">
                </app-update-number-result>
            } @else {
                <div class="readonly-result-layout">
                    <app-number-calc-sources-button [number]="element" *ngIf="shouldShowCalculationSources(element)">
                    </app-number-calc-sources-button>
                    <app-number-result [result]="element.result" [target]="element.recordTarget" [numberType]="element.type"></app-number-result>
                </div>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
            <ng-container *ngIf="!readonly">
                {{'numbers.target' | translate}}
            </ng-container>
            <ng-container *ngIf="readonly">
                {{'numbers.weeklyTarget' | translate}}
            </ng-container>
        </th>
        <td mat-cell *matCellDef="let element">
            <app-number-target [target]="element.recordTarget" [numberType]="element.type"></app-number-target>
        </td>
    </ng-container>

    <ng-container matColumnDef="resultToDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
            <div>
                <div>{{'numbers.qtd' | translate}}</div>
                <div>{{'numbers.result' | translate}}</div>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <app-number-result [result]="element.resultToDate" [target]="element.targetToDate"
                [numberType]="element.type"></app-number-result>
        </td>
    </ng-container>

    <ng-container matColumnDef="targetToDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
            <div>
                <div>{{'numbers.qtd' | translate}}</div>
                <div>{{'numbers.target' | translate}}</div>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <app-number-target [target]="element.targetToDate" [numberType]="element.type"></app-number-target>
        </td>
    </ng-container>

    <ng-container matColumnDef="resultSummary">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'numbers.resultSummary' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-number-result-summary [number]="element"></app-number-result-summary>
        </td>
    </ng-container>

    <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'numbers.department' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.department?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'numbers.category' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            @if (element.category; as category) {
                <app-category-display [category]="category"></app-category-display>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="actionDiscuss">
        <th mat-header-cell *matHeaderCellDef>
            {{'actionDiscuss' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-add-number-child-action-button
                [source]="element"
                [disabled]="disabled"
                (actionAdded)="actionUpdated(element)">
            </app-add-number-child-action-button>
            <app-add-number-child-discussion-button
                [source]="element"
                [disabled]="disabled"
                (discussionAdded)="discussionAdded(element)">
            </app-add-number-child-discussion-button>
        </td>
    </ng-container>

    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>
            <ng-content select="app-table-settings-menu"></ng-content>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="button-holder">
                <app-feed-button 
                    (openFeed)="openFeed(element)"
                    [reference]="element.feedPartition">
                </app-feed-button>

                <ng-container *ngTemplateOutlet="extraOptionsTemplate; context: { $implicit: element }"></ng-container>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="inlineChart">
        <td mat-cell *matCellDef="let number" [attr.colspan]="(displayedColumns$ | async)?.length">
            <div class="chart-container" *ngIf="showInlineCharts">
                <app-number-chart [number]="number" [maintainAspectRatio]="false"></app-number-chart>
            </div>
        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let numberItem" [attr.colspan]="(displayedColumns$ | async)?.length">
            <!-- Table for Actions -->
            <app-child-actions-table [actions]="getChildActions(numberItem) | async"
                [disabled]="disabled" [menuType]="fromMeeting ? 'edit' : 'feedOnly'"
                (reloadParentItem)="actionUpdated(numberItem)"
                *ngIf="isExpanded(numberItem) && (getChildActions(numberItem) | async)?.length"
                @detailExpand>
            </app-child-actions-table>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns$ | async;" [wfRowFocus]="row" [wfRowFocusIndex]="i"
        [class.wf-number-update-required]="highlightUpdateRequired && !isUpdated(row)" [class.row-before-chart]="showInlineCharts"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: ['inlineChart']" [wfRowFocus]="row" [wfRowFocusIndex]="i"
        [class.wf-number-update-required]="highlightUpdateRequired && !isUpdated(row)" [class.hidden]="!showInlineCharts"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
    <tr class="mat-mdc-row" *matNoDataRow>
        <td [attr.colSpan]="(displayedColumns$ | async)?.length">
            <span class="no-records">
                {{'NoRecordsFound.noRecordsFound!' | translate}}
            </span>
        </td>
    </tr>

    <ng-container *matHeaderRowDef="[]">
        <tr *ngIf="limitExceeded">
            <td [attr.colspan]="(displayedColumns$ | async)?.length">
                <app-number-exceeded-header></app-number-exceeded-header>
            </td>
        </tr>
    </ng-container>
</table>