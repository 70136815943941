import { trigger } from "@angular/animations";
import { AfterViewInit, Component, computed, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CategoryDetailDto, CurrentCompanyDto, CurrentTeamDto, GetAnnualGoalDto, GetAnnualGoalStrategyDto, GetGoalDto, GetTeamUserDelegationDto, GoalExtrasDto, PlanGoalsApi, RecurrenceDto, RequireNote, ResourceRequiredDto, SimpleAnnualGoalDto, SimpleAnnualGoalStrategyDto, SimpleCategoryDto, SimpleCompanyDto, SimpleCompanyTeamDto, SimpleDepartmentDto, SimpleSubCategoryDto, SimpleTeamDto, SimpleUserDto, UpdateGoalDto, UpdateTeamUserDelegationDto } from "@api";
import * as moment from "moment";
import { combineLatest, debounceTime, defer, distinctUntilChanged, EMPTY, filter, identity, map, Observable, of, startWith, Subscription, switchMap, tap } from "rxjs";

import { AnnualGoalRepository, CategoryRepository, DepartmentRepository, IQuarter, PeriodRepository, TeamRepository, TeamSettingsRepository } from "~repositories";
import { UserRepository } from "~repositories/user.repository";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { PlanningStatus, UpdateScheduleType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeExpandAnimationBuilder, fadeInAnimationBuilder } from "~shared/util/animations";
import { retryWithDelay } from "~shared/util/caching";
import { decimalValidator } from "~shared/util/custom-validators";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isDelegationEnabled } from "~shared/util/feature-helper";
import { setEnabledState } from "~shared/util/form-helper";
import { shareReplayUntil, tapFirst } from "~shared/util/rx-operators";
import { compareTeams, getTeamSearchData } from "~shared/util/team-helper";
import { getPlanningStatusNameKey, getRequireNoteNameKey, getUpdateScheduleTypeNameKey } from "~shared/util/translation-helper";
import { bindTriggeredDiscussionValue, DEFAULT_TRIGGER_AFTER_UPDATES, getTriggeredDiscussionValue, TRIGGER_AFTER_UPDATES_OPTIONS, TriggeredDiscussionType } from "~shared/util/triggered-discussion-helper";
import { getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

import { SuggestGoalImprovementDialogComponent } from "../suggest-goal-improvement-dialog/suggest-goal-improvement-dialog.component";

export type GoalInput = Partial<Pick<GetGoalDto, "heading" | "description">>;

interface IGoalDialogData {
    goal?: GetGoalDto;
    isCopy?: boolean;
    team: SimpleCompanyTeamDto;
    financialYear: number;
    planningPeriod: number | null;
    annualGoal?: GetAnnualGoalDto;
    readonly: boolean;
    input?: GoalInput;
}

export interface IAddGoalDialogData {
    company: CurrentCompanyDto | SimpleCompanyDto;
    team: CurrentTeamDto | SimpleTeamDto;
    financialYear: number;
    planningPeriod: number | null;
    annualGoal?: GetAnnualGoalDto;
    input?: GoalInput;
}

enum ResourceType {
    new = 0,
    internal = 1,
}

@Component({
    selector: "app-edit-goal-dialog",
    templateUrl: "./edit-goal-dialog.component.html",
    styleUrls: ["./edit-goal-dialog.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
        trigger("fadeExpand", fadeExpandAnimationBuilder()),
    ],
    standalone: false,
})
export class EditGoalDialogComponent extends WithDestroy() implements OnInit, OnDestroy, AfterViewInit {

    buttonState: ButtonState;
    disableAnimations = true;

    readonly planningStatuses = [
        PlanningStatus.draft,
        PlanningStatus.locked,
    ];

    readonly resourceTypes = [
        ResourceType.new,
        ResourceType.internal,
    ];

    readonly updateScheduleTypes = [
        UpdateScheduleType.everyPeriod,
        UpdateScheduleType.everyMeeting,
        UpdateScheduleType.custom,
    ];

    readonly requireNoteOptions = [
        RequireNote.never,
        RequireNote.whenOffTarget,
        RequireNote.always,
    ];

    readonly triggerDiscussionAfterUpdatesOptions = TRIGGER_AFTER_UPDATES_OPTIONS;

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly users$: Observable<SimpleUserDto[]>;
    readonly departments$: Observable<SimpleDepartmentDto[]>;
    readonly categories$: Observable<CategoryDetailDto[] | null>;
    readonly subCategories$: Observable<SimpleSubCategoryDto[] | null>;
    readonly myAnnualGoals$: Observable<GetAnnualGoalDto[]>;
    readonly otherAnnualGoals$: Observable<GetAnnualGoalDto[]>;
    readonly showAnnualGoalSeparator$: Observable<boolean>;
    readonly strategies$: Observable<GetAnnualGoalStrategyDto[] | null>;
    readonly delegationTeams$: Observable<SimpleCompanyTeamDto[]>;
    readonly delegationUsers$: Observable<SimpleUserDto[]>;

    readonly headingControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(1000)]);
    readonly goalStatusControl = this.fb.nonNullable.control(PlanningStatus.draft);
    readonly ownerControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly teamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly quarterControl = this.fb.control<IQuarter | null>(null, [Validators.required]);
    readonly dueDateControl = this.fb.control<moment.Moment | null>(null, [Validators.required]);
    readonly departmentControl = this.fb.control<string | null>(null);
    readonly categoryControl = this.fb.control<string | null>(null);
    readonly subCategoryControl = this.fb.control<string | null>(null);
    readonly annualGoalControl = this.fb.control<string | null>(null);
    readonly strategyControl = this.fb.control<string | null>(null);
    readonly isPrivateControl = this.fb.nonNullable.control(false);
    readonly isRecurringControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionTypeControl = this.fb.nonNullable.control<TriggeredDiscussionType>("offtarget");
    readonly triggerDiscussionAfterControl = this.fb.nonNullable.control<number>(DEFAULT_TRIGGER_AFTER_UPDATES);

    readonly scheduleTypeControl = this.fb.nonNullable.control(UpdateScheduleType.everyPeriod, [Validators.required]);
    readonly recurrenceControl = this.fb.nonNullable.control<RecurrenceDto | undefined>(undefined);

    readonly captureFrequencyControl = this.fb.group({
        type: this.scheduleTypeControl,
        recurrence: this.recurrenceControl
    });

    readonly requireNoteControl = this.fb.nonNullable.control(RequireNote.never, [Validators.required]);

    readonly resourceRequiredControl = this.fb.nonNullable.control(ResourceType.new);
    readonly newResourceControl = this.fb.group({
        cost: this.fb.nonNullable.control<number | undefined>(undefined, [decimalValidator]),
        comment: this.fb.nonNullable.control<string | undefined>(undefined, [Validators.maxLength(250)])
    });

    readonly internalResourceControl = this.fb.group({
        who: this.fb.nonNullable.control<string | undefined>(undefined, [Validators.maxLength(250)]),
        hours: this.fb.nonNullable.control<number | undefined>(undefined, [decimalValidator]),
    });

    readonly riskIfNotCompletedControl = this.fb.nonNullable.control<string | undefined>(undefined, [Validators.maxLength(250)]);
    readonly successMetricControl = this.fb.nonNullable.control<string | undefined>(undefined, [Validators.maxLength(250)]);

    readonly extrasControl = this.fb.group({
        riskIfNotCompleted: this.riskIfNotCompletedControl,
        successMetric: this.successMetricControl,
        resourceRequired: this.resourceRequiredControl,

        newResource: this.newResourceControl,
        internalResource: this.internalResourceControl,
    });

    readonly isDelegatedControl = this.fb.nonNullable.control(false);
    readonly delegationTeamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly delegationAssigneeControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly delegationControl = this.fb.group({
        team: this.delegationTeamControl,
        assignee: this.delegationAssigneeControl,
    });

    readonly form = this.fb.group({
        heading: this.headingControl,
        description: this.descriptionControl,
        goalStatus: this.goalStatusControl,
        owner: this.ownerControl,
        team: this.teamControl,
        quarter: this.quarterControl,
        dueDate: this.dueDateControl,
        department: this.departmentControl,
        category: this.categoryControl,
        subCategory: this.subCategoryControl,
        annualGoal: this.annualGoalControl,
        strategy: this.strategyControl,

        isPrivate: this.isPrivateControl,
        isRecurring: this.isRecurringControl,
        triggerDiscussion: this.triggerDiscussionControl,
        triggerDiscussionType: this.triggerDiscussionTypeControl,
        triggerDiscussionAfter: this.triggerDiscussionAfterControl,

        captureFrequency: this.captureFrequencyControl,
        requireNote: this.requireNoteControl,

        extras: this.extrasControl,

        isDelegated: this.isDelegatedControl,
        delegation: this.delegationControl,
    });

    readonly getUserName = getUserName;
    readonly getPlanningStatusNameKey = getPlanningStatusNameKey;
    readonly getUpdateScheduleTypeNameKey = getUpdateScheduleTypeNameKey;
    readonly getRequireNoteNameKey = getRequireNoteNameKey;
    readonly compareTeams = compareTeams;
    readonly getTeamSearchData = getTeamSearchData;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly ResourceType = ResourceType;

    get isAucbg(): boolean {
        return this.teamContext.settings.useAucbgMenus();
    }

    get hasRecurrence(): boolean {
        return this.scheduleTypeControl.value === UpdateScheduleType.custom;
    }

    get originalOwner(): SimpleUserDto | undefined {
        return this.goal?.owner;
    }

    get originalDepartment(): SimpleDepartmentDto | undefined {
        return this.goal?.department;
    }

    get originalCategory(): SimpleCategoryDto | undefined {
        return this.goal?.category;
    }

    get originalSubCategory(): SimpleSubCategoryDto | undefined {
        return this.goal?.category?.subCategory;
    }

    get originalDelegation(): GetTeamUserDelegationDto | undefined {
        return this.goal?.delegation;
    }

    get originalAnnualGoal(): SimpleAnnualGoalDto | undefined {
        return this.goal?.annualGoal;
    }

    get originalStrategy(): SimpleAnnualGoalStrategyDto | undefined {
        return this.goal?.strategy;
    }

    readonly selectedDateOutsideQuarter$: Observable<boolean>;

    readonly delegationVisible$: Observable<boolean>;

    readonly schedulingVisible = computed(() =>
        this.teamContext.features.flexibleSchedulingEnabled() ||
        this.teamContext.features.flexibleSchedulingTeased());

    readonly schedulingEnabled = this.teamContext.features.flexibleSchedulingEnabled;
    readonly annualPlanningEnabled = this.teamContext.features.annualPlanningEnabled;
    readonly noteEnforcementEnabled = this.teamContext.features.noteEnforcementEnabled;
    readonly triggeredDiscussionsEnabled = this.teamContext.features.triggeredDiscussionsEnabled;

    readonly suggestionsEnabled = computed(() =>
        this.teamContext.features.planningSuggestionsEnabled() ||
        this.userContext.isSuperAdmin() &&
        this.teamContext.features.superAdminPlanningSuggestionsEnabled());

    readonly newGoalSuggestionEnabled = computed(() =>
        this.suggestionsEnabled() && this.teamContext.features.newGoalSuggestionEnabled());

    readonly fixedFinancialYear: number | null = null;

    private readonly goal?: GetGoalDto;

    private readonly subscriptions = new Subscription();

    private hasGeneratedSuggestions = false;

    constructor(
        private readonly planGoalsApi: PlanGoalsApi,
        private readonly teamRepository: TeamRepository,
        private readonly userRepository: UserRepository,
        private readonly departmentRepository: DepartmentRepository,
        private readonly categoryRepository: CategoryRepository,
        private readonly annualGoalRepository: AnnualGoalRepository,
        private readonly periodRepository: PeriodRepository,
        private readonly teamSettingsRepository: TeamSettingsRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<EditGoalDialogComponent, GetGoalDto>,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: IGoalDialogData,
    ) {
        super();
        this.teamControl.setValue(data.team);
        this.goal = data.isCopy ? undefined : data.goal;
        if (data.planningPeriod != null) {
            this.quarterControl.setValue({
                financialYear: data.financialYear,
                quarter: data.planningPeriod,
            });
        }

        if (this.goal) {
            if (data.readonly || this.goal.planningStatus === PlanningStatus.locked || this.goal.isDelegated) {
                this.form.disable();
            } else {
                this.teamControl.disable();
                this.quarterControl.disable();
            }
        } else if (!this.teamContext.features.crossTeamFeaturesEnabled()) {
            this.teamControl.disable();
        }

        if (!this.isAucbg) {
            this.extrasControl.disable();
        }

        //#region Drop-Down Data Binding
        const companyTeamId$ = valueAndChanges(this.teamControl).pipe(
            filter(Boolean),
            map((team) => ({ companyId: team.company.id, teamId: team.id })),
            distinctUntilChanged((left, right) => left.companyId === right.companyId && left.teamId === right.teamId),
        );
        const companyId$ = companyTeamId$.pipe(
            map(({ companyId }) => companyId),
            distinctUntilChanged(),
        );

        this.teams$ = defer(() => this.teamRepository.getClientInstanceTeams(data.team.company.id));
        this.users$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.userRepository.getTeamMembers(companyId, teamId)),
            // If we are creating a new goal (and not copying), choose a default owner after loading team members.
            data.goal ? identity : tapFirst(this.setDefaultOwner),
            tap(this.availableUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.departments$ = companyId$.pipe(
            switchMap(companyId => this.departmentRepository.getDepartments(companyId)),
            tap(this.availableDepartmentsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.categories$ = companyId$.pipe(
            switchMap(companyId => this.categoryRepository.getCategories(companyId)),
            tap(this.availableCategoriesChanged),
            // As we hide the categories field if we don't have any categories, we set to null to simplify view logic.
            map(categories => !categories.length ? null : categories),
            shareReplayUntil(this.destroyed$),
        );
        this.subCategories$ = combineLatest({
            categories: this.categories$,
            categoryId: valueAndChanges(this.categoryControl),
        }).pipe(
            map(({ categories, categoryId }) => categories?.find(c => c.id === categoryId)?.subCategories ?? []),
            tap(this.availableSubCategoriesChanged),
            // As we hide the subcategories field if we don't have any subcategories, we set to null to simplify view logic.
            map(subCategories => !subCategories.length ? null : subCategories),
            shareReplayUntil(this.destroyed$),
        );

        const annualGoals$ = data.annualGoal ? of([data.annualGoal]) : combineLatest({
            companyTeamId: companyTeamId$,
            financialYear: valueAndChanges(this.quarterControl).pipe(
                map(q => q?.financialYear),
                distinctUntilChanged(),
            ),
        }).pipe(
            switchMap(({ companyTeamId: { companyId, teamId }, financialYear }) =>
                financialYear == null ? of([]) : this.annualGoalRepository.getAnnualGoals(companyId, teamId, financialYear).pipe(
                    tap(this.availableAnnualGoalsChanged),
                )),
            shareReplayUntil(this.destroyed$),
        );

        this.myAnnualGoals$ = annualGoals$.pipe(
            map(goals => goals.filter(g => g.owner?.userId === this.userContext.userId())),
            shareReplayUntil(this.destroyed$),
        );

        this.otherAnnualGoals$ = annualGoals$.pipe(
            map(goals => goals.filter(g => g.owner?.userId !== this.userContext.userId())),
            shareReplayUntil(this.destroyed$),
        );

        this.showAnnualGoalSeparator$ = combineLatest({
            myAnnualGoals: this.myAnnualGoals$,
            otherAnnualGoals: this.otherAnnualGoals$,
        }).pipe(
            map(x => !!x.myAnnualGoals.length && !!x.otherAnnualGoals.length),
            shareReplayUntil(this.destroyed$),
        );

        this.strategies$ = combineLatest({
            annualGoals: annualGoals$,
            annualGoalId: valueAndChanges(this.annualGoalControl)
        }).pipe(
            map(({ annualGoals, annualGoalId }) => annualGoals.find(g => g.id === annualGoalId)?.strategies ?? []),
            tap(this.availableStrategiesChanged),
            // As we hide the strategies field if we don't have any strategies, we set to null to simplify view logic.
            map(strategies => !strategies.length ? null : strategies),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationTeams$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.teamSettingsRepository.getDelegationTeams(companyId, teamId)),
            tap(this.availableDelegatedTeamsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationUsers$ = valueAndChanges(this.delegationTeamControl).pipe(
            switchMap(team => !team ? of([]) : this.userRepository.getTeamMembers(team.company.id, team.id)),
            tap(this.availableDelegatedUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        //#endregion

        //#region Quarter Date Checking
        const quarterDateRange$ = combineLatest({
            companyId: companyId$,
            quarter: valueAndChanges(this.quarterControl),
        }).pipe(
            debounceTime(50),
            switchMap(({ companyId, quarter }) => !quarter ? of(null) :
                this.periodRepository.getPeriods(companyId, quarter.financialYear).pipe(
                    retryWithDelay(),
                    map(periods => periods.find(p => p.index === quarter.quarter)),
                )
            ),
            map(period => !period ? null : {
                startDate: moment.utc(period.startDate),
                endDate: moment.utc(period.endDate).add(-1, "day").endOf("month")
            }),
            tap(this.quarterDateRangeChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.selectedDateOutsideQuarter$ = combineLatest({
            dueDate: valueAndChanges(this.dueDateControl),
            quarterDateRange: quarterDateRange$,
        }).pipe(
            map(({ dueDate, quarterDateRange }) => {
                if (!dueDate || !quarterDateRange) return false;
                return dueDate < quarterDateRange.startDate || dueDate > quarterDateRange.endDate;
            }),
            shareReplayUntil(this.destroyed$),
        );
        //#endregion

        //#region Plan Features
        this.delegationVisible$ = this.teamContext.companyTeam$.pipe(
            map(isDelegationEnabled),
            switchMap(enabled => {
                // If the form is disabled, show the delegation section if enabled and set but do no further processing
                if (this.form.disabled) return of(enabled && !!this.goal?.delegation);
                // Delegation is otherwise visible if enabled and there are some delegation teams set
                const visible$ = !enabled ? of(false) : this.delegationTeams$.pipe(map(teams => !!teams.length));
                return visible$.pipe(
                    tap(visible => {
                        if (!visible) {
                            this.isDelegatedControl.setValue(false);
                            this.isDelegatedControl.disable();
                            this.delegationControl.disable();
                        } else {
                            if (this.form.enabled) {
                                this.isDelegatedControl.enable();
                            }
                        }
                    }),
                    // While the delegation teams are loading, should be based on whether delegation settings currently exist.
                    startWith(!!this.goal?.delegation),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
        //#endregion

        if (data.goal) {
            this.bindGoal(data.goal);
        } else {
            if (data.input) {
                this.bindInput(data.input);
            }
            if (data.annualGoal) {
                this.annualGoalControl.setValue(data.annualGoal.id);
                this.annualGoalControl.disable();
                this.fixedFinancialYear = data.annualGoal.financialYear;
                // As a goal can only be linked to annual goals from its team, when fixing the annual goal
                // we implicitly fix the team.
                this.teamControl.disable();
            }
        }
    }

    static openForAdd(dialog: MatDialog, data: IAddGoalDialogData) {
        return this.openInternal(dialog, {
            goal: undefined,
            team: {
                id: data.team.id,
                name: data.team.name,
                company: {
                    id: data.company.id,
                    name: data.company.name,
                    clientId: data.company.clientId,
                },
            },
            financialYear: data.financialYear,
            planningPeriod: data.planningPeriod,
            annualGoal: data.annualGoal,
            input: data.input,
            isCopy: false,
            readonly: false,
        });
    }

    static openForEdit(dialog: MatDialog, goal: GetGoalDto, isCopy = false, readonly = false) {
        const { company, team } = isCopy ? getDelegatedItemCompanyTeam(goal) : goal;
        return this.openInternal(dialog, {
            goal,
            team: {
                id: team.id,
                name: team.name,
                company: {
                    id: company.id,
                    name: company.name,
                    clientId: company.clientId,
                },
            },
            financialYear: goal.financialYear,
            planningPeriod: goal.planningPeriod,
            isCopy,
            readonly
        });
    }

    private static openInternal(dialog: MatDialog, data: IGoalDialogData) {
        return dialog.open<EditGoalDialogComponent, IGoalDialogData, GetGoalDto>(EditGoalDialogComponent, {
            width: "750px",
            data: data
        });
    }

    ngOnInit(): void {
        const isOwnedByMe$ = valueAndChanges(this.ownerControl).pipe(
            map(owner => owner === this.userContext.userId()));
        this.subscriptions.add(combineLatest({
            isOwnedByMe: isOwnedByMe$,
            isDelegated: valueAndChanges(this.isDelegatedControl),
        }).subscribe(({ isOwnedByMe, isDelegated }) => {
            if (!this.form.enabled) return;
            if (isOwnedByMe && !isDelegated) {
                this.isPrivateControl.enable();
            } else {
                this.isPrivateControl.disable();
                this.isPrivateControl.setValue(false);
            }
        }));
        this.subscriptions.add(valueAndChanges(this.resourceRequiredControl).subscribe((type: ResourceType) => {
            if (!this.form.enabled) return;
            switch (type) {
                case ResourceType.internal:
                    this.newResourceControl.disable();
                    this.internalResourceControl.enable();
                    break;
                case ResourceType.new:
                default:
                    this.newResourceControl.enable();
                    this.internalResourceControl.disable();
                    break;
            }
        }));
        this.subscriptions.add(valueAndChanges(this.isDelegatedControl).subscribe(isDelegated => {
            if (!isDelegated) {
                this.delegationControl.disable();
                this.delegationTeamControl.setValue(null);
                this.delegationAssigneeControl.setValue(null);
            } else {
                if (this.form.enabled) {
                    this.delegationControl.enable();
                }
            }
        }));
        this.subscriptions.add(valueAndChanges(this.isPrivateControl).subscribe(isPrivate => {
            if (!this.form.enabled) return;
            const control = this.triggerDiscussionControl;
            if (control.disabled === isPrivate) return;
            if (isPrivate) {
                // We cannot have a private goal with triggered discussions
                control.setValue(false);
                control.disable();
            } else {
                control.enable();
            }
        }));
        this.subscriptions.add(valueAndChanges(this.triggerDiscussionControl).subscribe(this.handleTriggerDiscussionChange));
        this.subscriptions.add(valueAndChanges(this.triggerDiscussionTypeControl).subscribe(this.handleTriggerDiscussionTypeChange));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    suggestImprovement = () => {
        if (!this.suggestionsEnabled()) return;
        this.getSuggestionInternal().subscribe();
    };

    save = () => {
        if (!this.form.valid || this.buttonState) return;

        of(null).pipe(
            switchMap(() => {
                if (this.goal || this.hasGeneratedSuggestions || !this.newGoalSuggestionEnabled()) return of(null);
                return this.getSuggestionInternal(true).pipe(
                    // If we apply the suggestion, we don't want to save the goal but rather let the user make further edits.
                    switchMap(status => status === "apply" || status === "abort" ? EMPTY : of(null)),
                );
            }),
            tap(() => this.buttonState = "loading"),
            switchMap(() => {
                const dto: UpdateGoalDto = {
                    heading: this.headingControl.value ?? "",
                    description: this.descriptionControl.value ?? "",
                    goalStatus: this.goalStatusControl.value,
                    ownerUserId: this.ownerControl.value ?? "",
                    dueDateLocal: this.dueDateControl.value?.toISOString() ?? "",
                    departmentId: this.departmentControl.value ?? undefined,
                    categoryId: this.categoryControl.value ?? undefined,
                    subCategoryId: this.subCategoryControl.value ?? undefined,
                    annualGoalId: this.annualGoalControl.value ?? undefined,
                    strategyId: this.strategyControl.value ?? undefined,

                    isPrivate: this.isPrivateControl.value,
                    isRecurring: this.isRecurringControl.value,
                    requireNote: this.requireNoteControl.value,
                    detectProblemAfterUpdates: getTriggeredDiscussionValue(this.form.controls),

                    extras: this.extrasControl.enabled ? this.getExtras() : undefined,

                    scheduleDefinition: this.captureFrequencyControl.value,

                    delegation: this.getDelegation(),
                };

                if (this.goal) {
                    return this.planGoalsApi.updateGoal(
                        this.goal.company.id,
                        this.goal.team.id,
                        toFiscalQuarter({ financialYear: this.goal.financialYear, quarter: this.goal.planningPeriod }),
                        this.goal.id,
                        dto,
                    );
                } else {
                    const team = this.teamControl.value;
                    return this.planGoalsApi.addGoal(
                        team?.company.id ?? "",
                        team?.id ?? "",
                        toFiscalQuarter(this.quarterControl.value as IQuarter),
                        dto,
                    );
                }
            }),
        ).subscribe({
            next: (goal) => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(goal);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };

    getUserId = (user: SimpleUserDto) => user.userId;
    getDepartmentId = (department: SimpleDepartmentDto) => department.id;
    getDepartmentName = (department: SimpleDepartmentDto | null | undefined) => department?.name ?? "";
    getCategoryId = (category: CategoryDetailDto): string => category?.id ?? "";
    getCategoryDisplay = (category: CategoryDetailDto | null | undefined) => category?.description ?? "";
    getSubCategoryId = (subCategory: SimpleSubCategoryDto): string => subCategory?.id ?? "";
    getSubCategoryDisplay = (subCategory: SimpleSubCategoryDto | null | undefined): string => subCategory?.description ?? "";
    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined): string => team?.name ?? "";

    getResourceTypeNameKey = (type: ResourceType): string => {
        switch (type) {
            case ResourceType.new: return "resourceType.new";
            case ResourceType.internal: return "resourceType.internal";
            default: return "";
        }
    };

    private bindGoal = (goal: GetGoalDto) => {
        this.headingControl.setValue(goal.heading);
        this.descriptionControl.setValue(goal.description);
        this.goalStatusControl.setValue(goal.goalStatus);
        this.ownerControl.setValue(goal.owner?.userId ?? null);
        this.quarterControl.setValue({
            financialYear: goal.financialYear,
            quarter: goal.planningPeriod,
        });
        this.dueDateControl.setValue(moment(goal.dueDateLocal).utc(true));
        this.departmentControl.setValue(goal.department?.id ?? null);
        this.categoryControl.setValue(goal.category?.id ?? null);
        this.subCategoryControl.setValue(goal.category?.subCategory?.id ?? null);
        this.annualGoalControl.setValue(goal.annualGoal?.id ?? null);
        this.strategyControl.setValue(goal.strategy?.id ?? null);

        this.isPrivateControl.setValue(goal.isPrivate);
        this.isRecurringControl.setValue(goal.isRecurring);
        bindTriggeredDiscussionValue(this.form.controls, goal.detectProblemAfterUpdates);

        this.scheduleTypeControl.setValue(goal.scheduleDefinition.type ?? UpdateScheduleType.everyPeriod);
        this.recurrenceControl.setValue(goal.scheduleDefinition.recurrence);
        this.requireNoteControl.setValue(goal.requireNote);

        if (goal.extras) this.bindExtras(goal.extras);

        if (goal.delegation) this.bindDelegation(goal.delegation);
    };

    private bindExtras = (goalExtras: GoalExtrasDto) => {
        this.riskIfNotCompletedControl.setValue(goalExtras.riskIfNotCompleted);
        this.successMetricControl.setValue(goalExtras.successMetric);
        const resourceRequired = goalExtras.resourceRequired as ResourceRequiredDto | undefined;
        switch (resourceRequired?.type) {
            case "newResource":
                this.resourceRequiredControl.setValue(ResourceType.new);
                this.newResourceControl.patchValue(resourceRequired);
                break;
            case "internalResource":
                this.resourceRequiredControl.setValue(ResourceType.internal);
                this.internalResourceControl.patchValue(resourceRequired);
                break;
        }
    };

    private bindDelegation = (delegation: GetTeamUserDelegationDto) => {
        this.isDelegatedControl.setValue(true);
        this.delegationTeamControl.setValue({
            ...delegation.team,
            company: delegation.company,
        });
        this.delegationAssigneeControl.setValue(delegation.assignee?.userId ?? null);
    };

    private bindInput = (input: GoalInput) => {
        if (input.heading) this.headingControl.setValue(input.heading);
        if (input.description) this.descriptionControl.setValue(input.description);
    };

    private getExtras = (): GoalExtrasDto => ({
        riskIfNotCompleted: this.riskIfNotCompletedControl.value,
        successMetric: this.successMetricControl.value,
        resourceRequired: this.getResourceRequired(),
    });

    private getResourceRequired = (): ResourceRequiredDto | undefined => {
        switch (this.resourceRequiredControl.value as ResourceType) {
            case ResourceType.new:
                return {
                    type: "newResource",
                    ...this.newResourceControl.value,
                };
            case ResourceType.internal:
                return {
                    type: "internalResource",
                    ...this.internalResourceControl.value,
                };
            default: return undefined;
        }
    };

    private getDelegation = (): UpdateTeamUserDelegationDto | undefined => {
        if (!this.isDelegatedControl.value) return undefined;
        const team = this.delegationTeamControl.value;
        const assignee = this.delegationAssigneeControl.value;
        if (!team || !assignee) return undefined;
        return {
            companyId: team.company.id,
            teamId: team.id,
            assigneeUserId: assignee,
        };
    };

    private setDefaultOwner = (users: SimpleUserDto[]): void => {
        // Ensure the goal is a new goal and the owner is not yet set.
        if (this.goal || this.ownerControl.value) return;

        const currentUserId = this.userContext.userId();
        // Ensure the user is in the current team.
        if (!users.some(u => u.userId === currentUserId)) return;

        this.ownerControl.setValue(currentUserId);
    };

    private availableUsersChanged = (users: SimpleUserDto[]) => {
        if (this.ownerControl.value && !users.some(x => x.userId === this.ownerControl.value)) {
            this.ownerControl.setValue(null);
        }
    };

    private availableDepartmentsChanged = (departments: SimpleDepartmentDto[]) => {
        if (this.departmentControl.value && !departments.some(d => d.id === this.departmentControl.value)) {
            this.departmentControl.setValue(null);
        }
    };

    private availableCategoriesChanged = (categories: CategoryDetailDto[]) => {
        if (!categories.length) {
            // Ensures even if the list of sub-categories is not subscribed to (due to no categories)
            // the control will be cleared and disabled appropriately.
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
        }
        if (this.categoryControl.value && !categories.some(x => x.id === this.categoryControl.value)) {
            this.categoryControl.setValue(null);
        }
    };

    private availableSubCategoriesChanged = (subCategories: SimpleSubCategoryDto[]) => {
        if (!subCategories.length) {
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
            return;
        }

        if (this.form.enabled) this.subCategoryControl.enable();
        if (this.subCategoryControl.value && !subCategories.some(x => x.id === this.subCategoryControl.value)) {
            this.subCategoryControl.setValue(null);
        }
    };

    private availableAnnualGoalsChanged = (annualGoals: GetAnnualGoalDto[]) => {
        if (this.annualGoalControl.value && !annualGoals.some(g => g.id === this.annualGoalControl.value)) {
            this.annualGoalControl.setValue(null);
        }
    };

    private availableStrategiesChanged = (strategies: GetAnnualGoalStrategyDto[]) => {
        if (!strategies.length) {
            this.strategyControl.setValue(null);
            this.strategyControl.disable();
            return;
        }

        if (this.form.enabled) this.strategyControl.enable();
        if (this.strategyControl.value && !strategies.some(s => s.id === this.strategyControl.value)) {
            this.strategyControl.setValue(null);
        }
    };

    private availableDelegatedTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        const currentTeam = this.delegationTeamControl.value;
        if (currentTeam && !teams.some(t => compareTeams(t, currentTeam))) {
            this.delegationTeamControl.setValue(null);
        }
    };

    private availableDelegatedUsersChanged = (users: SimpleUserDto[]) => {
        if (this.delegationAssigneeControl.value && !users.some(x => x.userId === this.delegationAssigneeControl.value)) {
            this.delegationAssigneeControl.setValue(null);
        }
    };

    private quarterDateRangeChanged = (quarterDateRange: { startDate: moment.Moment; endDate: moment.Moment } | null) => {
        if (!quarterDateRange) return;
        if (!this.form.enabled || !!this.goal || this.dueDateControl.dirty) return;
        this.dueDateControl.setValue(quarterDateRange.endDate.clone());
    };

    private handleTriggerDiscussionChange = (enabled: boolean) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionTypeControl, enabled);
        setEnabledState(this.triggerDiscussionAfterControl, enabled && this.triggerDiscussionTypeControl.value === "offtarget");
    };

    private handleTriggerDiscussionTypeChange = (type: TriggeredDiscussionType) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionAfterControl, type === "offtarget" && this.triggerDiscussionControl.value);
    };

    private getSuggestionInternal = (fromSave: boolean = false): Observable<"apply" | "abort" | "ignore"> => {
        if (!this.headingControl.valid) return of("ignore");
        const heading = this.headingControl.value;
        const description = this.descriptionControl.value ?? undefined;
        const team = this.teamControl.value;
        if (!heading || !team) return of("ignore");

        return SuggestGoalImprovementDialogComponent.open(this.dialog, {
            companyId: team.company.id,
            teamId: team.id,
            goal: {
                heading,
                description,
            },
            fromSave,
        }).afterClosed().pipe(
            tap(result => {
                this.hasGeneratedSuggestions = true;
                if (result && result.status === "apply") {
                    const suggestion = result.suggestion;
                    this.headingControl.setValue(suggestion.heading);
                    this.descriptionControl.setValue(suggestion.description);
                }
            }),
            map(result => result?.status ?? "ignore"),
        );
    };
}
