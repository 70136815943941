import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GoalRecordDetailDto, SimpleCompanyTeamDto, SimpleUserDto } from "@api";
import { Subscription } from "rxjs";

import { GoalHomepageDialogComponent } from "~homepage";
import { buildRecordMergeHandler, GoalReference, GoalStateEvent, GoalStateService } from "~services/state";
import { getGoalStatusSortOrder } from "~shared/goal-status";
import { defaultGoalsFilterPredicate } from "~shared/util/table-filtering";
import { getUserName } from "~shared/util/user-helper";

export interface ViewGoalsDialogData {
    team: SimpleCompanyTeamDto;
    goals: GoalRecordDetailDto[];
    financialYear: number;
    quarter: number;
    week: number;
}

@Component({
    selector: "app-view-goals-dialog",
    templateUrl: "./view-goals-dialog.component.html",
    styleUrls: ["./view-goals-dialog.component.scss"],
    standalone: false,
})
export class ViewGoalsDialogComponent implements OnInit, OnDestroy {

    @ViewChild(MatSort, { static: true }) set sort(value: MatSort | null) {
        this.dataSource.sort = value;
    }

    get sort(): MatSort | null {
        return this.dataSource.sort;
    }

    readonly dataSource = new MatTableDataSource<GoalRecordDetailDto>;
    readonly columns = ["heading", "description", "owner", "dueDate", "status", "options"];

    readonly team: SimpleCompanyTeamDto;
    readonly financialYear: number;
    readonly quarter: number;
    readonly week: number;

    readonly getUserName = getUserName;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly goalStateService: GoalStateService,
        private readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: ViewGoalsDialogData,
    ) {
        this.dataSource.data = data.goals;
        this.team = data.team;
        this.financialYear = data.financialYear;
        this.quarter = data.quarter;
        this.week = data.week;

        this.dataSource.filterPredicate = defaultGoalsFilterPredicate;

        this.dataSource.sortingDataAccessor = (goal, property) => {
            switch (property) {
                case "owner": return getUserName(goal.owner);
                case "status": return getGoalStatusSortOrder(goal.status);
                case "department": return goal.department?.name ?? "";
                default:
                    return (goal as never)[property] ?? "";
            }
        };
    }

    static open(dialog: MatDialog, data: ViewGoalsDialogData) {
        return dialog.open(ViewGoalsDialogComponent, {
            maxWidth: "1250px",
            data,
            autoFocus: "first-heading",
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.goalStateService.eventsForGoals(...this.dataSource.data).subscribe(this.handleStateEvent));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => this.dataSource.filter = user?.userId ?? "";

    viewGoal = (goal: GoalRecordDetailDto, focusFeed = false) =>
        GoalHomepageDialogComponent.open(this.dialog, goal, { focusFeed });

    openFeed = (goal: GoalRecordDetailDto) => this.viewGoal(goal, /* focusFeed: */ true);

    private handleStateEvent = (event: GoalStateEvent) => {
        const data = this.dataSource.data;
        const mergeHandler = buildRecordMergeHandler<GoalRecordDetailDto, GoalReference>();
        const newData = mergeHandler(data, event);
        if (newData) this.dataSource.data = newData;
    };
}
