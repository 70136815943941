<table mat-table class="wf-table" [dataSource]="dataSource" matSort [matSortActive]="defaultSort ?? ''" matSortDirection="asc"
    [matSortDisableClear]="!!defaultSort" [trackBy]="trackByIdAndWeek" multiTemplateDataRows wfTableFocus>
    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef>
            <wf-expand-button [expanded]="canExpandAll && areAllExpanded"
                (expand)="expandAll()" (collapse)="collapseAll()"
                [disabled]="!canExpandAll"
                [expandTooltip]="'expandAll' | translate"
                [collapseTooltip]="'collapseAll' | translate">
            </wf-expand-button>
        </th>
        <td mat-cell *matCellDef="let element">
            <wf-expand-button *ngIf="element.actionsCount" [expanded]="isExpanded(element)"
                (expand)="expand(element)" (collapse)="collapse(element)"
                [expandTooltip]="'expandItem' | translate: { item: element.description }"
                [collapseTooltip]="'collapseItem' | translate: { item: element.description }">
            </wf-expand-button>
        </td>
    </ng-container>

    <ng-container matColumnDef="actionsCount">
        <th mat-header-cell *matHeaderCellDef>
            {{'reports.actionsCount' | translate}}</th>
        <td mat-cell *matCellDef="let element">
            <wf-child-count [count]="element.actionsCount"></wf-child-count>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef>
            {{'reports.updated' | translate}}
        </th>
        <td mat-cell *matCellDef="let report">
            <app-completion-indicator [completed]="report.reportIsSet">
            </app-completion-indicator>
        </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'reports.description' | translate}}
        </th>
        <td mat-cell *matCellDef="let report" class="wf-column-heading">
            <a class="multiline-description" (click)="viewReport(report)" wfEmptyLink>
                {{report.description}}
            </a>
            <app-notes-callout *ngIf="report.notes" [notes]="report.notes"></app-notes-callout>
        </td>
    </ng-container>

    <ng-container matColumnDef="week">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'period.week' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ 'period.weekDisplayShort' | translate: { financialYear: element.financialYear % 100, quarter: element.planningPeriod, week: element.week } }}
        </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
            <app-table-user-filter (selected)="applyOwnerFilter($event)" withSortHeader>
                {{ 'reports.owner' | translate }}
            </app-table-user-filter>
        </th>
        <td mat-cell *matCellDef="let report">
            <app-delegated-user-display [entity]="report">
                {{ getUserName(report.owner) }}
            </app-delegated-user-display>
        </td>
    </ng-container>
    <ng-container matColumnDef="updater">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'reports.updater' | translate }}
        </th>
        <td mat-cell *matCellDef="let report">
            <app-delegated-user-display [entity]="report" mode="updater">
                {{ getUserName(report.updater) }}
            </app-delegated-user-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'reports.team' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-team-display [team]="element.team" [company]="element.company"></app-team-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="reports">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.mat-column-reports-display]="readonly">
            {{ 'reports.reports' | translate }}
        </th>
        <td mat-cell *matCellDef="let report" [class.mat-column-reports-display]="readonly">
            <div class="reports-container">
                <app-report-upload-button *ngIf="!readonly" [record]="report" (updated)="reportUpdated($event)"
                    [disabled]="disabled">
                </app-report-upload-button>
                <app-report-attachment-links [report]="report">
                </app-report-attachment-links>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="reportSummary">
        <th mat-header-cell *matHeaderCellDef>
            {{'reports.reportSummary' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-report-summary [report]="element"></app-report-summary>
        </td>
    </ng-container>

    <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'reports.department' | translate}}
        </th>
        <td mat-cell *matCellDef="let report">
            {{ report.department?.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'reports.category' | translate}}
        </th>
        <td mat-cell *matCellDef="let report">
            @if (report.category; as category) {
                <app-category-display [category]="category"></app-category-display>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="actionDiscuss">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'actionDiscuss' | translate }}
        </th>
        <td mat-cell *matCellDef="let report">
            <app-add-report-child-action-button
                [source]="report"
                [disabled]="disabled"
                (actionAdded)="actionUpdated(report)">
            </app-add-report-child-action-button>
            <app-add-report-child-discussion-button
                [source]="report"
                [disabled]="disabled"
                (discussionAdded)="discussionAdded(report)">
            </app-add-report-child-discussion-button>
        </td>
    </ng-container>

    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>
            <ng-content select="app-table-settings-menu"></ng-content>
        </th>
        <td mat-cell *matCellDef="let report">
            <div class="button-holder">
                <app-feed-button (openFeed)="openFeed(report)" [reference]="report.feedPartition">
                </app-feed-button>

                <ng-container *ngTemplateOutlet="extraOptionsTemplate; context: { $implicit: report }"></ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let reportItem" [attr.colspan]="(displayedColumns$ | async)?.length">
            <!-- Table for Actions -->
            <app-child-actions-table [actions]="getChildActions(reportItem) | async"
                [disabled]="disabled" [menuType]="fromMeeting ? 'edit' : 'feedOnly'"
                (reloadParentItem)="actionUpdated(reportItem)"
                *ngIf="isExpanded(reportItem) && (getChildActions(reportItem) | async)?.length"
                @detailExpand>
            </app-child-actions-table>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async;"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns$ | async;" [wfRowFocus]="row"
        [wfRowFocusIndex]="i" [class.wf-report-update-required]="highlightUpdateRequired && !row.reportIsSet"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
    <tr class="mat-mdc-row" *matNoDataRow>
        <td [attr.colspan]="(displayedColumns$ | async)?.length">
            <span class="no-records">
                {{'NoRecordsFound.noRecordsFound!' | translate}}
            </span>
        </td>
    </tr>

    <ng-container *matHeaderRowDef="[]">
        <tr *ngIf="limitExceeded">
            <td [attr.colspan]="(displayedColumns$ | async)?.length">
                <app-report-exceeded-header></app-report-exceeded-header>
            </td>
        </tr>
    </ng-container>
</table>