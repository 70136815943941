import { DayOfWeek, SimpleCategoryDto, SimpleCompanyDto, SimpleCompanyTeamDto, SimpleTeamDto } from "@api";
import type { Moment } from "moment";

import { getUserName } from "./user-helper";

export declare type ISorter<T> = (a: T, b: T) => number;

type SortSelector<TInput, TValue> = (item: TInput) => TValue;

declare interface ISorterBuilder<T> {
    (): ISorter<T>;
    <TInput>(selector: SortSelector<TInput, T>): ISorter<TInput>;
};

declare interface ISorterDefinition<T> {
    readonly ascending: ISorterBuilder<T>;
    readonly descending: ISorterBuilder<T>;
};

const makeSortBuilder = <TField>(sorter: ISorter<TField>): ISorterBuilder<TField> =>
    <TInput>(selector?: SortSelector<TInput, TField>): ISorter<TInput> => {
        if (!selector) selector = i => i as any as TField;
        return (a, b) => sorter(selector(a), selector(b));
    };

export const makeSortDefinition = <TField>(ascendingFunc: ISorter<TField>): ISorterDefinition<TField> => ({
    ascending: makeSortBuilder(ascendingFunc),
    descending: makeSortBuilder((a, b) => -ascendingFunc(a, b)),
});

export const sortNumber = makeSortDefinition<number | null | undefined>((a, b) => (a ?? 0) - (b ?? 0));

export const sortString = makeSortDefinition<string | null | undefined>((a, b) => (a ?? "").localeCompare(b ?? ""));

export const sortBoolean = makeSortDefinition<boolean | null | undefined>(sortNumber.ascending(a => +!!a));

export const sortDate = makeSortDefinition<Date | Moment | null | undefined>(sortNumber.ascending(a => a?.valueOf() ?? 0));

export const getIsoDayOfWeekOrder = (day: DayOfWeek | null | undefined): number =>
    day === DayOfWeek.sunday ? 7 : (day ?? 0);

export const sortIsoDayOfWeek = makeSortDefinition<DayOfWeek | null | undefined>(
    sortNumber.ascending(getIsoDayOfWeekOrder),
);

/** Applies the sorters in order allowing for multi-level sorting. */
export const sortMultiple = <T>(...allSorters: ISorter<T>[]): ISorter<T> => (a, b) => {
    for (const sorter of allSorters) {
        const result = sorter(a, b);
        if (result) return result;
    }

    return 0;
};

interface IUser {
    firstName: string;
    lastName: string;
}

export const sortUser = makeSortDefinition<IUser | null | undefined>(sortString.ascending(getUserName));

export const sortTeam = makeSortDefinition<SimpleCompanyTeamDto>(sortMultiple(
    sortString.ascending(t => t.company.name),
    sortString.ascending(t => t.name),
));

interface ICompanyTeam {
    company: SimpleCompanyDto;
    team: SimpleTeamDto;
}

export const sortCompanyTeam = makeSortDefinition<ICompanyTeam>(sortMultiple(
    sortString.ascending(ct => ct.company.name),
    sortString.ascending(ct => ct.team.name),
));

const separator = "     ";

export const getCompanyTeamSortAccessor = (company: SimpleCompanyDto, team: SimpleTeamDto): string =>
    `${company.name}${separator}${team.name}`;

export const getTeamSortAccessor = (team: SimpleCompanyTeamDto): string =>
    getCompanyTeamSortAccessor(team.company, team);

export const sortCategory = makeSortDefinition<SimpleCategoryDto | undefined>(sortMultiple(
    sortString.ascending(c => c?.description ?? ""),
    sortString.ascending(c => c?.subCategory?.description),
));

export const getCategorySortAccessor = (category: SimpleCategoryDto | null | undefined): string => {
    if (!category) return "";
    if (!category.subCategory) return category.description;
    return `${category.description}${separator}${category.subCategory.description}`;
};
