/* eslint-disable max-classes-per-file */
import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, ContentChild, Directive, Input, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { GetActionDto, GoalRecordDetailDto, PlanGoalsApi, SimpleUserDto } from "@api";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";

import { GoalHomepageDialogComponent } from "~homepage";
import { ActionStateService } from "~services/state";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { EntityType, GoalStatus } from "~shared/enums";
import { getGoalStatusSortOrder } from "~shared/goal-status";
import { expandOnEnterAnimation } from "~shared/util/animations";
import { mergeChildUpdatesFrom } from "~shared/util/children-state-helper";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { ExpansionControllerWithLoader } from "~shared/util/expansion-controller";
import { GoalColumn } from "~shared/util/goal-columns";
import { getSortablePeriodString } from "~shared/util/period-helper";
import { getCategorySortAccessor } from "~shared/util/sorters";
import { defaultGoalsFilterPredicate } from "~shared/util/table-filtering";
import { trackByIdAndWeek } from "~shared/util/table-helper";
import { getUserName } from "~shared/util/user-helper";

import { GenericArrayTable } from "../generic-table/generic-table.directive";
import { ExtraTableOptionsContext } from "../generic-table/generic-table-shared";

const DEFAULT_COLUMNS: GoalColumn[] = ["heading", "description", "owner", "dueDate", "status"];

declare type ExtendedGoalColumn = GoalColumn |
    "expand" | "actionsCount" | "actionDiscuss" | "options";

@Directive({
    selector: "[appExtraGoalOptions]",
    standalone: false,
})
export class ExtraGoalOptionsDirective {
    static ngTemplateContextGuard(dir: ExtraGoalOptionsDirective, ctx: unknown): ctx is ExtraTableOptionsContext<GoalRecordDetailDto> {
        return true;
    }
}

@Component({
    selector: "app-generic-goals-table",
    templateUrl: "./generic-goals-table.component.html",
    styleUrls: ["./generic-goals-table.component.scss"],
    animations: [
        trigger("detailExpand", expandOnEnterAnimation),
    ],
    standalone: false,
})
export class GenericGoalsTableComponent extends GenericArrayTable<GoalRecordDetailDto, GoalColumn> {

    @Input() set highlightUpdateRequired(value: BooleanInput) {
        this.highlightUpdateRequiredInternal = coerceBooleanProperty(value);
    }

    get highlightUpdateRequired(): boolean {
        return this.highlightUpdateRequiredInternal;
    }

    @Input() set showChildActions(value: BooleanInput) {
        this.showChildActionsSubject.next(coerceBooleanProperty(value));
    }

    get showChildActions(): boolean {
        return this.showChildActionsSubject.value;
    }

    @Input({ transform: booleanAttribute }) fromMeeting = false;

    @ContentChild(ExtraGoalOptionsDirective, { read: TemplateRef, static: false })
    extraOptionsTemplate: TemplateRef<ExtraTableOptionsContext<GoalRecordDetailDto>> | null = null;

    @ViewChild(MatSort) set matSort(value: MatSort) {
        this.dataSource.sort = value;
    }

    readonly displayedColumns$: Observable<ExtendedGoalColumn[]>;

    readonly getUserName = getUserName;
    readonly trackByIdAndWeek = trackByIdAndWeek;

    get canExpandAll(): boolean {
        return !!this.expandableGoals.length;
    }

    get areAllExpanded(): boolean {
        return this.expandableGoals.every(this.childActionsController.isExpanded);
    }

    private readonly childActionsController = new ExpansionControllerWithLoader<GoalRecordDetailDto, GetActionDto>(
        g => g.id,
        g => {
            const { company, team } = getDelegatedItemCompanyTeam(g);
            return this.planGoalsApi.getActionsForGoal(
                company.id,
                team.id,
                toFiscalQuarter({ financialYear: g.financialYear, quarter: g.planningPeriod }),
                g.id,
            ).pipe(
                mergeChildUpdatesFrom(this.actionStateService.events$, g.id, EntityType.goal),
            );
        },
        this.destroyed$,
    );

    private get expandableGoals() {
        return this.dataSource.data.filter(g => g.actionsCount);
    }

    private highlightUpdateRequiredInternal = false;
    private readonly showChildActionsSubject = new BehaviorSubject<boolean>(true);

    constructor(
        private readonly planGoalsApi: PlanGoalsApi,
        private readonly actionStateService: ActionStateService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.dataSource.filterPredicate = defaultGoalsFilterPredicate;

        this.displayedColumns$ = combineLatest({
            columns: this.columnsSubject,
            showChildActions: this.showChildActionsSubject,
        }).pipe(
            map(({ columns, showChildActions }) => [
                ...(showChildActions ? ["expand", "actionsCount"] as const : []),
                ...columns,
                "actionDiscuss",
                "options",
            ]),
        );

        this.columns = DEFAULT_COLUMNS;
        this.defaultSort = "heading";
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => this.dataSource.filter = user?.userId ?? "";

    viewGoal = (goal: GoalRecordDetailDto, focusFeed = false) =>
        GoalHomepageDialogComponent.open(this.dialog, goal, { focusFeed })
            .componentInstance.events$.subscribe(event => this.updated.emit(event));

    openFeed = (goal: GoalRecordDetailDto) => this.viewGoal(goal, /* focusFeed: */ true);

    isUpdated = (goal: GoalRecordDetailDto): boolean => goal.status !== GoalStatus.updateRequired;

    goalUpdated = (goal: GoalRecordDetailDto) =>
        this.updated.emit({ type: "updated", item: goal });

    discussionAdded = (goal: GoalRecordDetailDto) => {
        this.updated.emit({ type: "child", item: goal, childType: "discussion" });
    };

    actionUpdated = (goal: GoalRecordDetailDto) => {
        this.updated.emit({ type: "child", item: goal, childType: "action" });
    };

    isExpanded = (goal: GoalRecordDetailDto) => this.childActionsController.isExpanded(goal);

    expandAll = () => this.expandableGoals.forEach(this.childActionsController.expand);
    collapseAll = () => this.expandableGoals.forEach(this.childActionsController.collapse);

    expand = (goal: GoalRecordDetailDto) => this.childActionsController.expand(goal);
    collapse = (goal: GoalRecordDetailDto) => this.childActionsController.collapse(goal);

    getChildActions = (goal: GoalRecordDetailDto) => this.childActionsController.getChildren(goal);

    protected sortingDataAccessor = (data: GoalRecordDetailDto, property: GoalColumn) => {
        switch (property) {
            case "heading": return data.heading;
            case "description": return data.description;
            case "week": return getSortablePeriodString(data);
            case "owner": return getUserName(data.owner);
            case "team": return data.team.name;
            case "dueDate": return data.dueDate;
            case "statusSummary":
            case "status": return getGoalStatusSortOrder(data.status);
            case "department": return data.department?.name ?? "";
            case "category": return getCategorySortAccessor(data.category);
        }
    };

    protected getDefaultColumns = () => DEFAULT_COLUMNS;
}
