export enum Features {
    reports = "reports",
    news = "news",
    approvals = "approvals",
    recurringActions = "recurringAction",
    globalCreate = "globalCreate",
    numberChartsForMinutes = "minutesNumberCharts",
    xeroIntegration = "xeroIntegration",
    multiNumberChart = "multiNumberChart",
    userPlanChange = "userPlanChange",
    partnerPlanSetup = "partnerPlanSetup",
    delegation = "delegation",
    negativeStatusNotes = "negativeStatusNotes",
    noteEnforcement = "noteEnforcement",
    workInstruction = "workInstruction",
    customGoalStatuses = "customGoalStatuses",
    league = "league",
    triggeredDiscussions = "triggeredDiscussions",
    performanceTargets = "performanceTargets",
    teamAnalytics = "teamAnalytics",
    goalCancellation = "goalCancellation",
    calculatedNumbers = "calculatedNumbers",
    advancedCalculations = "advancedCalculations",
    personalWatchlists = "personalWatchlists",
    planningSuggestions = "planningSuggestions",
    superAdminPlanningSuggestions = "superAdminPlanningSuggestions",
    newGoalSuggestion = "newGoalSuggestion",
    discussionSuggestions = "discussionSuggestions",
    superAdminDiscussionSuggestions = "superAdminDiscussionSuggestions",
    customMeetingStructure = "customMeetingStructure",
    userAnalytics = "userAnalytics",
    teamWatchlists = "teamWatchlists",
    watchlistSharing = "watchlistSharing",
    dailyUpdatedNumbers = "dailyUpdatedNumbers",
    deployedNumbers = "deployedNumbers",
}

export enum GlobalFeatures {
    registration = "registration",
}
