<button mat-icon-button type="button" color="primary" (click)="openDialog()" 
    [disabled]="!canViewDetails || (hasAccess$ | async) !== true"
    [matTooltip]="tooltipKey | translate"
    [class.daily-update-button]="isDailyUpdated">
    @if (isDeployed) {
        <mat-icon>device_hub</mat-icon>
    } @else if (isCalculated) {
        <mat-icon>calculate</mat-icon>
    } @else {
        <mat-icon>date_range</mat-icon>
    }
</button>