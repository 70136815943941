import { CaptureMethod, EntityReferenceDetailsDto, EntityType, GetEnterpriseNumberDto, GetNumberDto, NumberRecordDetailDto, NumberSourceDto, NumberTargetDto, SimpleNumberDto } from "@api";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "~/environments/environment";
import { FeatureContext } from "~services/contexts";
import { NumberType } from "~shared/enums";

import { getIsoDayOfWeekOrder, makeSortDefinition, sortCompanyTeam, sortIsoDayOfWeek, sortMultiple, sortNumber, sortString } from "./sorters";
import { getUpdateScheduleDescription } from "./translation-helper";

export declare type NumberStatus = "ontarget" | "offtarget" | null;

const valueIsSet = <T>(value: T | undefined | null): value is T => value !== null && value !== undefined;

export const getNumberPrefix = (type: NumberType) => type === NumberType.currency ? "$" : "";
export const getNumberSuffix = (type: NumberType) => type === NumberType.percentage ? "%" : "";

export const hasLowerBound = (target?: NumberTargetDto): boolean => valueIsSet(target) && valueIsSet(target.lowerBound);
export const hasUpperBound = (target?: NumberTargetDto): boolean => valueIsSet(target) && valueIsSet(target.upperBound);

export const targetIsSet = (target: NumberTargetDto | null | undefined): target is NumberTargetDto =>
    valueIsSet(target) && (valueIsSet(target.lowerBound) || valueIsSet(target.upperBound));

export const getNumberTargetStatus = (result: number | null | undefined, target: NumberTargetDto | null | undefined): NumberStatus => {
    if (!valueIsSet(result) || !targetIsSet(target)) return null;

    return (!valueIsSet(target?.lowerBound) || target?.lowerBound <= result) &&
        (!valueIsSet(target?.upperBound) || target?.upperBound >= result) ? "ontarget" : "offtarget";
};

/**
 * Checks if the number type supports adding numbers across multiple weeks.
 * Note: Currently only the percentage type does not support adding numbers.
 */
export const supportsAddingNumbers = (type: NumberType) => type !== NumberType.percentage;

export const getNumberStatusSortOrder = (result: number | null | undefined, target: NumberTargetDto | null | undefined) => {
    // First in order are numbers that have not been updated.
    if (!valueIsSet(result)) return 0;
    // After that, numbers that are off target.
    // Finally, numbers that are either on target, or have been updated but have no target.
    return getNumberTargetStatus(result, target) === "offtarget" ? 1 : 2;
};

declare type NumberDto = GetNumberDto | NumberRecordDetailDto | SimpleNumberDto | GetEnterpriseNumberDto;

export const sortNumberDefinition = makeSortDefinition<NumberDto>(sortMultiple(
    sortString.ascending(n => n.description),
    sortIsoDayOfWeek.ascending(n => n.updateDay),
    sortCompanyTeam.ascending(),
));

/**
 * Returns a sortable string that is the order a number should appear when sorted by description.
 * This is required as we want to sort numbers by description, and then by the update day of the week.
 * Note: we also sort by ID or source ID here. This is to ensure that daily numbers with the same name are grouped together.
 *
 * @param number The number to sort by.
 */
export const numberDescriptionSortAccessor = (number: GetNumberDto | NumberRecordDetailDto): string => {
    if (number.updateDay == null && !number.dailyUpdateDefinition) return number.description;
    // Add spaces to ensure that the ID/update day is sorted after the description.
    // This is intended to ensure that numbers with similar names are purely sorted according to the description, not the id/week day.
    const separator = "     ";
    const tokens = [
        number.description,
        number.updateDay == null ? number.id : (number.source?.id ?? number.id),
        getIsoDayOfWeekOrder(number.updateDay),
    ];
    return tokens.join(separator);
};

/**
 * Gets the most relevant week for a number, which can be used to get "current" calculation information.
 *
 * @param number The number to determine the relevant week for.
 * @returns The relevant week. Will only ever be null if the number is never scheduled.
 */
export const getRelevantWeek = (number: GetNumberDto | NumberRecordDetailDto): number | null => {
    if ("week" in number) return number.week;
    if (number.latestWeek != null) return number.latestWeek;
    // If latestWeek is null, we may be before the quarter has started. In this case, use the first scheduled week.
    const weeks = Object.keys(number.weekTargets)
        .filter(weekString => Object.prototype.hasOwnProperty.call(number.weekTargets, weekString))
        .map(weekString => parseInt(weekString, 10))
        .sort(sortNumber.ascending());
    // This implies the number is not scheduled for any time in the quarter it exists.
    if (!weeks.length) return null;
    return weeks[0];
};

export const mapSourceToReference = (source: NumberSourceDto): EntityReferenceDetailsDto => ({
    company: source.company,
    team: source.team,
    heading: source.description,
    description: undefined,
    extras: undefined,
    type: EntityType.number,
    id: source.id,
    week: undefined,
});

export const shouldShowCalculationSources = (number: GetNumberDto, features: FeatureContext): boolean => {
    if (number.captureMethod === CaptureMethod.calculated && features.calculatedNumbersEnabled()) return true;
    if (number.captureMethod === CaptureMethod.deployed && features.deployedNumbersEnabled()) return true;
    if (number.dailyUpdateDefinition && features.dailyUpdatedNumbersEnabled()) return true;
    return false;
};

export const shouldShowWorkInstructionVideo = (workInstructionLink: string): boolean => {
    workInstructionLink = workInstructionLink.toLowerCase();
    return workInstructionLink.endsWith(".mp4") && environment.videoEmbedPaths.some(path => workInstructionLink.startsWith(path));
};

export const getNumberUpdateScheduleDescription = (translate: TranslateService, number: GetNumberDto): string =>
    number.dailyUpdateDefinition ? translate.instant("numbers.dailyUpdates.daily") :
        getUpdateScheduleDescription(translate, number.scheduleDefinition);
