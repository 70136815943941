<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>{{'discussions.discussion' | translate}}</h4>
    <mat-dialog-content>
        <app-origin-header *ngIf="origin" [origin]="origin"></app-origin-header>
        <div class="row">
            <div class="col-sm-12 textarea_col">
                <mat-form-field>
                    <mat-label>{{'discussions.heading' | translate}}</mat-label>
                    <textarea matInput name="heading" formControlName="heading" required maxlength="250" cdkFocusInitial></textarea>
                    <mat-error *ngIf="!headingControl.valid">
                        {{'discussions.headingRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 textarea_col">
                <mat-form-field>
                    <mat-label>{{'discussions.descriptionReason' | translate}}</mat-label>
                    <textarea matInput name="description" formControlName="description" maxlength="1000"></textarea>
                    <mat-error *ngIf="!descriptionControl.valid">
                        {{'discussions.descriptionRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field *ngIf="!readonly && (teamSelectionEnabled$ | async); else disabledTeam">
                    <mat-label>{{'discussions.team' | translate}}</mat-label>
                    <app-auto-select name="team" formControlName="team" [options]="teams$ | async" [optionValueFunc]="getTeamId"
                        [optionDisplayFunc]="getTeamDisplay" [searchFunc]="getTeamSearchData" required>
                        <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                    </app-auto-select>
                    <mat-error *ngIf="!teamControl.valid">
                        {{'discussions.teamRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <ng-template #disabledTeam>
                    <mat-form-field [appPlanTooltip]="isTeamEditable && (crossTeamTeased$ | async) ?
                    ('featureUnavailable.inputTooltip' | translate) : undefined">
                        <mat-label>{{'discussions.team' | translate}}</mat-label>
                        <input matInput type="text" [value]="originalTeamName" disabled required />
                        <mat-icon svgIcon="wf-stars-complete" matSuffix class="feature-stars"
                            *ngIf="isTeamEditable && (crossTeamTeased$ | async)"></mat-icon>
                    </mat-form-field>
                </ng-template>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'discussions.type' | translate}}</mat-label>
                    <mat-select disableOptionCentering name="type" formControlName="type">
                        <mat-option *ngFor="let type of types" [value]="type">
                            {{ getTypeNameKey(type) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field *ngIf="!readonly && (creatorControl.enabled || !originalCreator); else fakeCreator">
                    <mat-label>{{'discussions.creator' | translate}}</mat-label>
                    <app-auto-select name="creator" formControlName="creator" [options]="users$ | async"
                        [optionValueFunc]="getUserId" [optionDisplayFunc]="getUserName" required>
                    </app-auto-select>
                    <mat-error *ngIf="!creatorControl.valid">
                        {{'discussions.creatorRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <ng-template #fakeCreator>
                    <mat-form-field>
                        <mat-label>{{'discussions.creator' | translate}}</mat-label>
                        <input matInput type="text" [value]="getUserName(originalCreator)" disabled required />
                    </mat-form-field>
                </ng-template>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'discussions.priority' | translate}}</mat-label>
                    <mat-select disableOptionCentering name="priority" formControlName="priority" required>
                        <mat-option *ngFor="let priority of priorities" [value]="priority">
                            {{ getPriorityNameKey(priority) | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!priorityControl.valid">
                        {{'discussions.priorityRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="readonly && originalDepartment">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'discussions.department' | translate}}</mat-label>
                    <input matInput type="text" [value]="originalDepartment.name" disabled />
                </mat-form-field>
            </div>
        </div>
        <ng-container *ngIf="!readonly && (departments$ | async); let departments">
            <div class="row" *ngIf="departments.length">
                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{'discussions.department' | translate}}</mat-label>
                        <app-auto-select name="department" formControlName="department" [options]="departments$ | async"
                            [optionValueFunc]="getDepartmentId" [optionDisplayFunc]="getDepartmentName">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <div class="row" *ngIf="!readonly && (categories$ | async); let categories">
            <div class="col-sm-6" *ngIf="categories.length">
                <mat-form-field>
                    <mat-label>{{'discussions.category' | translate}}</mat-label>
                    <app-auto-select name="category" formControlName="category" [options]="categories$ | async"
                        [optionValueFunc]="getCategoryId" [optionDisplayFunc]="getCategoryDisplay">
                    </app-auto-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="subCategories$ | async; let subCategories">
                <div class="col-sm-6" *ngIf="subCategories.length > 0">
                    <mat-form-field>
                        <mat-label>{{'discussions.subCategory' | translate}}</mat-label>
                        <app-auto-select name="subCategory" formControlName="subCategory" [options]="subCategories"
                            [optionValueFunc]="getSubCategoryId" [optionDisplayFunc]="getSubCategoryDisplay">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
        <div class="row" *ngIf="readonly && originalCategory">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'discussions.category' | translate}}</mat-label>
                    <input matInput type="text" [value]="originalCategory.description" disabled />
                </mat-form-field>
            </div>
            <div class="col-sm-6" *ngIf="originalSubCategory">
                <mat-form-field>
                    <mat-label>{{'discussions.subCategory' | translate}}</mat-label>
                    <input matInput type="text" [value]="originalSubCategory.description" disabled required />
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="form.errors">
            <div class="col-xs-12" role="alert">
                <p class="warning-box" *ngIf="form.errors?.capReached" translate="planUpgrade.discussions.capReached"></p>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" *ngIf="!readonly">
        <app-status-button type="button" *ngIf="canResolveNow$ | async" (buttonClick)="save(true)" [state]="resolveNowState" [disabled]="form.disabled" @fadeIn>
            {{ "discussions.solution.resolveNow" | translate }}
        </app-status-button>
        <app-status-button type="submit" [state]="buttonState" [disabled]="form.disabled">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>