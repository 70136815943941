import { Injectable } from "@angular/core";
import { GetNumberDto, NumberRecordDetailDto, PlanNumbersApi } from "@api";
import { concatMap, from, Observable } from "rxjs";

import { toFiscalQuarter } from "~shared/commonfunctions";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";

import { BaseStateServiceWithRefresh } from "./base-state.service";
import { PeriodicItemReference } from "./comparators";
import { StateEvent } from "./state-shared";

export type NumberReference = PeriodicItemReference;

export type NumberStateEvent = StateEvent<NumberRecordDetailDto, NumberReference>;

@Injectable({
    providedIn: "root",
})
export class NumberStateService extends BaseStateServiceWithRefresh<NumberRecordDetailDto, NumberReference,
    GetNumberDto | NumberRecordDetailDto | NumberReference> {

    constructor(private readonly planNumbersApi: PlanNumbersApi) {
        super();
    }

    eventsForNumbers = (...numbers: (GetNumberDto | NumberRecordDetailDto)[]) => this.eventsForItems(numbers);

    protected refreshItem = (item: NumberReference): Observable<NumberRecordDetailDto> =>
        this.planNumbersApi.getNumberRecords(
            item.companyId,
            item.teamId,
            toFiscalQuarter({ financialYear: item.financialYear, quarter: item.planningPeriod }),
            item.id,
        ).pipe(
            concatMap(records => from(records)),
        );

    protected toReference = (item: GetNumberDto | NumberRecordDetailDto | NumberReference): NumberReference => {
        if ("companyId" in item) return item;
        const { company, team } = getDelegatedItemCompanyTeam(item);
        return {
            companyId: company.id,
            teamId: team.id,
            financialYear: item.financialYear,
            planningPeriod: item.planningPeriod,
            id: item.id,
            collectionPeriod: "week" in item ? item.week : undefined,
        };
    };

    protected getRefreshReference = (item: NumberReference): NumberReference => ({
        ...item,
        // As we refresh all records for a number at once, our reference should ignore the collection period.
        // This ensures that we don't get double-up on refresh requests.
        collectionPeriod: undefined,
    });

    protected compareReferences = (ref1: NumberReference, ref2: NumberReference): boolean =>
        ref1.companyId === ref2.companyId &&
        ref1.teamId === ref2.teamId &&
        ref1.financialYear === ref2.financialYear &&
        ref1.planningPeriod === ref2.planningPeriod &&
        ref1.id === ref2.id &&
        (ref1.collectionPeriod == null || ref2.collectionPeriod == null || ref1.collectionPeriod === ref2.collectionPeriod);
}
