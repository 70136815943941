<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>{{ 'numbers.number' | translate }}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{ 'numbers.description' | translate }}</mat-label>
                    <textarea matInput type="text" formControlName="description" autocomplete="off" maxlength="250"
                        required cdkTextareaAutosize cdkAutosizeMinRows="2">
                    </textarea>
                    <mat-error *ngIf="descriptionControl.errors?.required"
                        translate="numbers.descriptionRequired"></mat-error>
                    <mat-error *ngIf="descriptionControl.errors?.maxlength"
                        translate="numbers.descriptionMaxLength"
                        [translateParams]="descriptionControl.errors?.maxlength"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.team' | translate }}</mat-label>
                    @if (teamControl.enabled) {
                        <app-auto-select name="team" formControlName="team" [options]="teams$ | async"
                            [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData" required>
                            <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getTeamDisplay(teamControl.value)" disabled required />
                    }
                    <mat-error *ngIf="!teamControl.valid"
                        translate="numbers.teamRequired"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.qtr' | translate }}</mat-label>
                    <wf-quarter-field name="quarter" formControlName="quarter"
                        [companyId]="teamControl.value?.company?.id" required>
                    </wf-quarter-field>
                    <mat-error *ngIf="!quarterControl.valid"
                        translate="numbers.qtrRequired"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.planningStatus' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="numberStatus" required>
                        <mat-option *ngFor="let status of planningStatuses" [value]="status">
                            {{ getPlanningStatusNameKey(status) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.numberType' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="numberType" required>
                        <mat-option *ngFor="let type of numberTypes" [value]="type">
                            {{ getNumberTypeNameKey(type) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.desiredResult' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="targetType">
                        <mat-option *ngFor="let target of targetTypes" [value]="target">
                            {{ getNumberTargetTypeNameKey(target) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="hasLowerTarget"
                [class.col-sm-4]="!hasUpperTarget"
                [class.col-sm-2]="hasUpperTarget"
                [class.col-xs-6]="hasUpperTarget">
                <mat-form-field>
                    <mat-label>
                        {{
                        (
                        hasUpperTarget ?
                        'numbers.targetLow' :
                        'numbers.target'
                        ) | translate
                        }}
                    </mat-label>
                    <input matInput type="number" formControlName="targetLowerBound" class="target"
                        autocomplete="off" />
                </mat-form-field>
            </div>
            <div *ngIf="hasUpperTarget"
                [class.col-sm-4]="!hasLowerTarget"
                [class.col-sm-2]="hasLowerTarget"
                [class.col-xs-6]="hasLowerTarget">
                <mat-form-field>
                    <mat-label>
                        {{
                        (
                        hasLowerTarget ?
                        'numbers.targetHigh' :
                        'numbers.target'
                        ) | translate
                        }}
                    </mat-label>
                    <input matInput type="number" formControlName="targetUpperBound" class="target"
                        autocomplete="off" />
                    <mat-error *ngIf="targetUpperBoundControl.errors?.greaterThan"
                        translate="numbers.errorTargetMustBeHigher"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <mat-checkbox formControlName="allowWeeklyTargets">
                    {{ 'numbers.setForEachWeek' | translate }}
                </mat-checkbox>
            </div>
            <div class="col-xs-12" *ngIf="supportsEnteringDeltas">
                <mat-checkbox formControlName="enteringTotals">
                    {{ 'numbers.enteringTotals' | translate }}
                </mat-checkbox>
            </div>
        </div>

        <div *ngIf="allowWeeklyTargetsControl.value"  @fadeExpand [@.disabled]="disableAnimations">
            <fieldset formGroupName="weekTargets">
                <legend translate="numbers.weeklyTargets"></legend>
                <div class="week-targets-container">
                    <div class="week-target" *ngFor="let week of weeks" [formGroupName]="week.toString()">
                        <div *ngIf="hasUpperTarget">
                            <mat-form-field [floatLabel]="upperTargetSet ? 'always' : 'auto'" class="mat-form-field-short mat-form-field-narrow">
                                <mat-label>
                                    {{ 'numbers.weekTarget' | translate: { week: week } }}
                                </mat-label>
                                <input matInput type="number" formControlName="upper" class="target"
                                    [placeholder]="targetUpperBoundControl.value?.toString() ?? ''" autocomplete="off" [name]="'upperTarget_' + week" />
                                <mat-hint *ngIf="hasLowerTarget"
                                    translate="numbers.high"></mat-hint>
                                <mat-error *ngIf="weekTargetsControl.controls[week.toString()].controls.upper?.errors?.greaterThan"
                                    translate="numbers.errorTargetInvalid"></mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="hasLowerTarget">
                            <mat-form-field [floatLabel]="lowerTargetSet ? 'always' : 'auto'" class="mat-form-field-short mat-form-field-narrow">
                                <mat-label>
                                    {{ 'numbers.weekTarget' | translate: { week: week } }}
                                </mat-label>
                                <input matInput type="number" formControlName="lower" class="target"
                                    [placeholder]="targetLowerBoundControl.value?.toString() ?? ''" autocomplete="off" [name]="'lowerTarget_' + week" />
                                <mat-hint *ngIf="hasUpperTarget"
                                    translate="numbers.low"></mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="row">
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.captureMethod' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="captureMethod" required>
                        <mat-option *ngFor="let c of captureMethods$ | async" [value]="c">
                            {{ getCaptureMethodNameKey(c) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.owner' | translate }}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select formControlName="owner" required [options]="users$ | async"
                            [optionDisplayFunc]="getUserName" [compareWith]="compareUsers"></app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getUserName(ownerControl.value)" disabled required />
                    }
                    <mat-error *ngIf="ownerControl.errors?.required"
                        translate="numbers.ownerRequired"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.updater' | translate }}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select formControlName="updater" [required]="!isDelegatedControl.value" [options]="users$ | async"
                            [optionDisplayFunc]="getUserName" [compareWith]="compareUsers"></app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getUserName(updaterControl.value)" disabled [required]="!isDelegatedControl.value" />
                    }
                    <mat-error *ngIf="updaterControl.errors?.required"
                        translate="numbers.updaterRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="calculation-container" *ngIf="captureMethodIsCalculated" @fadeExpand [@.disabled]="disableAnimations">
            <fieldset formGroupName="calculationDefinition">
                <legend>{{'numbers.calculation.definition' | translate}}</legend>
                <div class="calculation-row">
                    <div class="row">
                        <div class="col-sm-4 col-xs-12">
                            <mat-form-field>
                                <mat-label>{{ 'numbers.calculation.type' | translate }}</mat-label>
                                <mat-select disableOptionCentering formControlName="type" name="calculationType" required>
                                    <mat-option *ngFor="let type of calculationTypes" [value]="type">
                                        {{ getCalculationTypeNameKey(type) | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4 col-xs-6">
                            <mat-form-field>
                                <mat-label>{{ 'numbers.calculation.multiplier' | translate }}</mat-label>
                                <input matInput type="number" formControlName="multiplier" class="target"
                                    autocomplete="off" />    
                                <wf-help-indicator matSuffix [matTooltip]="'numbers.calculation.multiplierDivisorTooltip' | translate"></wf-help-indicator>
                                <mat-error *ngIf="calculationMultiplierControl.hasError('nonZero')"
                                    translate="numbers.calculation.multiplierNonZero"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4 col-xs-6">
                            <mat-form-field>
                                <mat-label>{{ 'numbers.calculation.divisor' | translate }}</mat-label>
                                <input matInput type="number" formControlName="divisor" class="target"
                                    autocomplete="off" />
                                <wf-help-indicator matSuffix [matTooltip]="'numbers.calculation.multiplierDivisorTooltip' | translate"></wf-help-indicator>
                                <mat-error *ngIf="calculationDivisorControl.hasError('nonZero')"
                                    translate="numbers.calculation.divisorNonZero"></mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <fieldset formArrayName="numbers">
                    <legend>{{'numbers.calculation.numbers' | translate}}</legend>
                    @for (calcNumberForm of calculationNumbersControl.controls; track calcNumberForm.controls.number.value.globalId; let index = $index) {
                        <div @fadeExpand [@.disabled]="disableAnimations">
                            <div [formGroupName]="index" class="calculation-number" [class.calculation-number-disabled]="calcNumberForm.disabled"
                                [class.calculation-number-with-operation]="calculationCanInvert(calculationTypeControl.value)"
                                *ngIf="calcNumberForm.controls.number.value; let number">
                                @if (calculationCanInvert(calculationTypeControl.value)) {
                                    <div class="calculation-number-operation">
                                        <mat-button-toggle-group formControlName="inverted" class="icon-toggle-group">
                                            @if (isCalculationTypeSum(calculationTypeControl.value)) {
                                                <mat-button-toggle [value]="false" [matTooltip]="'numbers.calculation.add' | translate">
                                                    <mat-icon fontSet="fa" fontIcon="fa-add"></mat-icon>
                                                </mat-button-toggle>
                                                <mat-button-toggle [value]="true" [matTooltip]="'numbers.calculation.subtract' | translate">
                                                    <mat-icon fontSet="fa" fontIcon="fa-minus"></mat-icon>
                                                </mat-button-toggle>
                                            } @else if (isCalculationTypeProduct(calculationTypeControl.value)) {
                                                <mat-button-toggle [value]="false" [matTooltip]="'numbers.calculation.multiply' | translate">
                                                    <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
                                                </mat-button-toggle>
                                                <mat-button-toggle [value]="true" [matTooltip]="'numbers.calculation.divide' | translate">
                                                    <mat-icon fontSet="fa" fontIcon="fa-divide"></mat-icon>
                                                </mat-button-toggle>
                                            }
                                        </mat-button-toggle-group>
                                    </div>
                                }
                                <div class="calculation-number-details">
                                    <div class="calculation-number-description">
                                        <span class="multiline-description">{{number.description}}</span>
                                        @if (number.updateDay != null) {
                                            {{ ' ' }}
                                            <span class="update-day">({{ getDayOfWeekNameKey(number.updateDay) | translate }})</span>
                                        }
                                    </div>
                                    <div class="calculation-number-team">
                                        <app-team-display [team]="number.team" [company]="number.company"></app-team-display>
                                    </div>
                                </div>
                                @if (calculationShowAdvancedControl.value && (advancedCalculationsEnabled$ | async)) {
                                    <div class="calculation-number-advanced">
                                        <mat-form-field>
                                            <mat-label>{{ 'numbers.calculation.weekOffset.label' | translate }}</mat-label>
                                            <mat-select formControlName="weekOffset">
                                                <mat-option [value]="0">{{ 'numbers.calculation.weekOffset.thisWeek' | translate }}</mat-option>
                                                <mat-option [value]="1">{{ 'numbers.calculation.weekOffset.lastWeek' | translate }}</mat-option>
                                                @for (week of [2, 3, 4]; track week) {
                                                    <mat-option [value]="week">{{ 'numbers.calculation.weekOffset.weeksAgo' | translate: { week } }}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field formGroupName="rollingDefinition">
                                            <mat-label>{{ 'numbers.calculation.rollingType.label' | translate }}</mat-label>
                                            <mat-select formControlName="calculationType">
                                                @for (type of rollingCalculationTypes; track type) {
                                                    <mat-option [value]="type">{{ getRollingCalculationTypeNameKey(type) | translate }}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="calcNumberForm.controls.rollingDefinition.controls.calculationType.value !== 'none'"
                                        formGroupName="rollingDefinition" class="calculation-number-rolling" @fadeIn>
                                        <mat-form-field *ngIf="calcNumberForm.controls.rollingDefinition.controls.duration; let durationControl">
                                            <mat-label>{{ 'numbers.calculation.rollingDuration' | translate }}</mat-label>
                                            <input type="number" matInput autocomplete="off" formControlName="duration" [min]="1" class="duration" />
                                            @if (durationControl.hasError("required")) {
                                                <mat-error translate="numbers.calculation.rollingDurationRequired"></mat-error>
                                            } @else if (durationControl.hasError("min")) {
                                                <mat-error translate="numbers.calculation.rollingDurationMin"></mat-error>
                                            } @else if (durationControl.hasError("pattern")) {                                                
                                                <mat-error translate="numbers.calculation.rollingDurationInteger"></mat-error>
                                            }
                                        </mat-form-field>
                                        <mat-form-field>
                                            <mat-label>{{ 'numbers.calculation.rollingDurationType.label' | translate }}</mat-label>
                                            <mat-select formControlName="durationType">
                                                @for (type of rollingDurationTypes; track type) {
                                                    <mat-option [value]="type">{{ getRollingDurationTypeNameKey(type, calcNumberForm.controls.rollingDefinition.controls.duration.value !== 1) | translate }}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                }
                                <div class="calculation-number-actions">
                                    <button type="button" mat-icon-button color="warn" (click)="calculationNumbersControl.removeAt(index)"
                                        [disabled]="calculationNumbersControl.disabled">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    <div class="calculation-row" *ngIf="calculationNumbersControl.errors" role="alert" @fadeExpand [@.disabled]="disableAnimations">
                        <mat-error *ngIf="calculationNumbersControl.hasError('required')"
                            translate="numbers.calculation.numbersRequired"></mat-error>
                        <mat-error *ngIf="calculationNumbersControl.hasError('circularReference')"
                            translate="numbers.calculation.numbersCircularReference"></mat-error>
                    </div>

                    <div class="calculation-row calculation-add-container" *ngIf="calculationNumbersControl.enabled">
                        <mat-form-field subscriptSizing="dynamic" class="calculation-add-selector">
                            <mat-label translate="numbers.calculation.addNumber"></mat-label>
                            <app-auto-select [formControl]="newCalculationNumberControl" [options]="unselectedNumbers$ | async"
                                [optionDisplayFunc]="getNumberDisplayFunc" [searchFunc]="getNumberSearchData" [groupWith]="groupNumbers"
                                [compareWith]="compareNumbers">
                                <ng-container *appAutoOption="let number">
                                    <span class="multiline-description">{{number.description}}</span>
                                    @if (number.updateDay != null) {
                                        {{ ' ' }}
                                        <span class="update-day">({{ getDayOfWeekNameKey(number.updateDay) | translate }})</span>
                                    }
                                    <wf-private-indicator *ngIf="number.isPrivate"></wf-private-indicator>
                                    <ng-container *ngIf="getNumberWarnings(number); let warnings">
                                        <mat-icon class="calculation-number-warning-icon" [wfTemplateTooltip]="warning">
                                            warning
                                        </mat-icon>
                                        <ng-template #warning>
                                            <div class="calculation-number-warning">
                                                <p>
                                                    <wf-translate-template key="numbers.calculation.mismatchWarning">
                                                        <span *wfTransTplContent="'problems'">
                                                            <ng-container *ngFor="let warning of warnings; let index = index; let last = last" [ngSwitch]="warning">
                                                                <ng-container *ngIf="!last && index">, </ng-container>
                                                                <ng-container *ngIf="last && index">{{ 'numbers.calculation.or' | translate }}</ng-container>
                                                                <ng-container *ngSwitchCase="'number_type_mismatch'">
                                                                    {{ 'numbers.calculation.numberTypeMismatch' | translate }}
                                                                </ng-container>
                                                                <ng-container *ngSwitchCase="'entry_type_mismatch'">
                                                                    {{ 'numbers.calculation.entryTypeMismatch' | translate }}
                                                                </ng-container>
                                                            </ng-container>
                                                        </span>
                                                    </wf-translate-template>
                                                </p>
                                                <p translate="numbers.calculation.mismatchWarningDisclaimer"></p>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </app-auto-select>
                        </mat-form-field>
                    </div>

                    @if (calculationNumbersControl.enabled && (advancedCalculationsEnabled$ | async)) {
                        <mat-checkbox [formControl]="calculationShowAdvancedControl">
                            {{ 'numbers.calculation.showAdvancedOptions' | translate }}
                        </mat-checkbox>
                    }
                </fieldset>
            </fieldset>
        </div>

        <div class="deployment-container" *ngIf="captureMethodIsDeployed" @fadeExpand [@.disabled]="disableAnimations">
            <fieldset>
                <legend>{{'numbers.deployment.definition' | translate}}</legend>
                <ng-container formGroupName="deploymentDefinition">
                    <div class="deployment-row">
                        <mat-form-field>
                            <mat-label>{{ 'numbers.calculation.type' | translate }}</mat-label>
                            <mat-select disableOptionCentering formControlName="calculationType" name="calculationType" required>
                                <mat-option *ngFor="let type of deploymentCalculationTypes " [value]="type">
                                    {{ getCalculationTypeNameKey(type) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>

                <fieldset>
                    <legend>{{'numbers.deployment.teams' | translate}}</legend>
                    
                    <ng-container formGroupName="deployedChildren">
                        @for (data of deployedTeamData$ | async; track data.companyTeamId) {
                            <div @fadeExpand [@.disabled]="disableAnimations">
                                <fieldset [formGroupName]="data.companyTeamId">
                                    <legend>{{ data.team.name }} - {{ data.team.company.name }}</legend>
                                    <div class="deployment-row deployment-details">
                                        <div class="owner-container">
                                            <mat-form-field>
                                                <mat-label>{{ 'numbers.owner' | translate }}</mat-label>
                                                @if (form.enabled) {
                                                    <app-auto-select formControlName="owner" required [options]="data.users$ | async"
                                                        [optionDisplayFunc]="getUserName" [compareWith]="compareUsers"></app-auto-select>
                                                } @else {
                                                    <input matInput type="text" [value]="getUserName(data.childForm.controls.owner.value)" disabled required />
                                                }
                                                <mat-error *ngIf="data.childForm.controls.owner.errors?.required"
                                                    translate="numbers.ownerRequired"></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="hasLowerTarget" class="target-container"
                                            [class.target-container-range]="hasUpperTarget">
                                            <mat-form-field>
                                                <mat-label>
                                                    {{
                                                    (
                                                    hasUpperTarget ?
                                                    'numbers.targetLow' :
                                                    'numbers.target'
                                                    ) | translate
                                                    }}
                                                </mat-label>
                                                <input matInput type="number" formControlName="targetLowerBound" class="target"
                                                    autocomplete="off" />
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="hasUpperTarget" class="target-container"
                                            [class.target-container-range]="hasLowerTarget">
                                            <mat-form-field>
                                                <mat-label>
                                                    {{
                                                    (
                                                    hasLowerTarget ?
                                                    'numbers.targetHigh' :
                                                    'numbers.target'
                                                    ) | translate
                                                    }}
                                                </mat-label>
                                                <input matInput type="number" formControlName="targetUpperBound" class="target"
                                                    autocomplete="off" />
                                                <mat-error *ngIf="data.childForm.controls.targetUpperBound.errors?.greaterThan"
                                                    translate="numbers.errorTargetMustBeHigher"></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="deployment-actions">
                                            <button type="button" mat-icon-button color="primary" (click)="editDeployedChild(data.childForm)"
                                                [disabled]="data.childForm.disabled" [matTooltip]="'Edit' | translate">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <button type="button" mat-icon-button color="warn" (click)="removeDeployedChild(data.team)"
                                                [disabled]="deploymentTeamsControl.disabled" [matTooltip]="'numbers.deployment.removeTeam' | translate">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        }
                    </ng-container>

                    <div class="deployment-row" *ngIf="deploymentTeamsControl.hasError('required')" role="alert" @fadeExpand [@.disabled]="disableAnimations">
                        <mat-error translate="numbers.deployment.teamsRequired"></mat-error>
                    </div>

                    <div class="deployment-row deployment-add-container" *ngIf="deploymentTeamsControl.enabled">
                        <mat-form-field subscriptSizing="dynamic" class="deployment-add-selector">
                            <mat-label translate="numbers.deployment.addTeam"></mat-label>
                            
                            <app-auto-select [formControl]="newDeploymentTeamControl" [options]="availableDeployableTeams$ | async"
                                [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData">
                                <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                            </app-auto-select>
                        </mat-form-field>
                    </div>
                </fieldset>
            </fieldset>
        </div>
        
        <!-- If capture method is automatic then allow configuring the external data -->
        <app-external-data *ngIf="captureMethodIsAutomatic && (integrationsEnabled$ | async)"formControlName="externalData"></app-external-data>

        <div class="row">
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>{{ 'numbers.department' | translate }}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select formControlName="department" name="department" [options]="departments$ | async"
                            [optionDisplayFunc]="getDepartmentName" [compareWith]="compareDepartments">
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getDepartmentName(departmentControl.value)" disabled />
                    }
                </mat-form-field>
            </div>
            @if (form.enabled && (categories$ | async); as categories) {
                <div class="col-sm-4">
                    <mat-form-field>
                        <mat-label>{{ 'numbers.category' | translate }}</mat-label>
                        <app-auto-select formControlName="category" [options]="categories"
                            [optionDisplayFunc]="getCategoryDisplay" [compareWith]="compareCategories">
                        </app-auto-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-4" *ngIf="subCategories$ | async; let subCategories">
                    <mat-form-field>
                        <mat-label>{{ 'numbers.subCategory' | translate }}</mat-label>
                        <app-auto-select formControlName="subCategory" [options]="subCategories"
                            [optionDisplayFunc]="getSubCategoryDisplay" [compareWith]="compareSubCategories">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            } @else if (form.disabled && categoryControl.value) {
                <div class="col-sm-4">
                    <mat-form-field>
                        <mat-label>{{'numbers.category' | translate}}</mat-label>
                        <input matInput type="text" [value]="getCategoryDisplay(categoryControl.value)" disabled />
                    </mat-form-field>
                </div>
                <div class="col-sm-4" *ngIf="subCategoryControl.value">
                    <mat-form-field>
                        <mat-label>{{ 'numbers.subCategory' | translate }}</mat-label>
                        <input matInput type="text" [value]="getSubCategoryDisplay(subCategoryControl.value)" disabled required />
                    </mat-form-field>
                </div>
            }
        </div>

        <div class="row">
            <!-- Capture Frequency -->
            <div class="col-sm-4" *ngIf="schedulingVisible$ | async">
                <mat-form-field *ngIf="schedulingEnabled$ | async; else fakeSchedule">
                    <mat-label>{{ "updateScheduleType.title" | translate }}</mat-label>
                    <mat-select [formControl]="scheduleTypeControl" name="scheduleType" required>
                        <mat-option *ngFor="let t of updateScheduleTypeOptions$ | async" [value]="t">
                            {{ getUpdateScheduleTypeNameKey(t) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <ng-template #fakeSchedule>
                    <app-fake-schedule-field></app-fake-schedule-field>
                </ng-template>
            </div>

            <div class="col-sm-4" *ngIf="noteEnforcementEnabled$ | async">
                <mat-form-field>
                    <mat-label>{{ 'numbers.requireNote' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="requireNote" required>
                        <mat-option *ngFor="let option of requireNoteOptions" [value]="option">
                            {{ getRequireNoteNameKey(option) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="daily-update-container" *ngIf="scheduleTypeControl.value === 'daily'" @fadeExpand [@.disabled]="disableAnimations">
            <fieldset>
                <legend>{{'numbers.dailyUpdates.definition' | translate}}</legend>
                <ng-container formGroupName="dailyUpdateDefinition">
                    <div class="daily-update-row">
                        <mat-form-field>
                            <mat-label>{{ 'numbers.dailyUpdates.updateDays' | translate }}</mat-label>
                            <mat-select disableOptionCentering formControlName="days" name="updateDays" multiple required>
                                <mat-option *ngFor="let day of updateDayOptions" [value]="day">
                                    {{ getDayOfWeekNameKey(day) | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="updateDaysControl.invalid" translate="numbers.dailyUpdates.updateDaysRequired"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="daily-update-row">
                        <mat-form-field>
                            <mat-label>{{ 'numbers.calculation.type' | translate }}</mat-label>
                            <mat-select disableOptionCentering formControlName="calculationType" name="calculationType" required>
                                <mat-option *ngFor="let type of dailyUpdateCalculationTypes" [value]="type">
                                    {{ getCalculationTypeNameKey(type) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container formGroupName="dailyChildren">
                    @for (day of updateDaysControl.value; track day) {
                        <fieldset [formGroupName]="day.toString()" *ngIf="dailyChildrenControl.controls[day.toString()]; let dayForm">
                            <legend>{{ getDayOfWeekNameKey(day) | translate }}</legend>
                            <div class="daily-update-row row">
                                <div class="col-sm-4">
                                    <mat-form-field>
                                        <mat-label>{{ 'numbers.updater' | translate }}</mat-label>
                                        @if (form.enabled) {
                                            <app-auto-select formControlName="updater" [required]="!isDelegatedControl.value" [options]="users$ | async"
                                                [optionDisplayFunc]="getUserName" [compareWith]="compareUsers"></app-auto-select>
                                        } @else {
                                            <input matInput type="text" [value]="getUserName(dayForm.controls.updater.value)" disabled [required]="!isDelegatedControl.value" />
                                        }
                                        <mat-error *ngIf="dayForm.controls.updater.errors?.required"
                                            translate="numbers.updaterRequired"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="hasLowerTarget" class="col-sm-4"
                                    [class.col-xs-6]="hasUpperTarget">
                                    <mat-form-field>
                                        <mat-label>
                                            {{
                                            (
                                            hasUpperTarget ?
                                            'numbers.targetLow' :
                                            'numbers.target'
                                            ) | translate
                                            }}
                                        </mat-label>
                                        <input matInput type="number" formControlName="targetLowerBound" class="target"
                                            autocomplete="off" />
                                    </mat-form-field>
                                </div>
                                <div *ngIf="hasUpperTarget" class="col-sm-4"
                                    [class.col-xs-6]="hasLowerTarget">
                                    <mat-form-field>
                                        <mat-label>
                                            {{
                                            (
                                            hasLowerTarget ?
                                            'numbers.targetHigh' :
                                            'numbers.target'
                                            ) | translate
                                            }}
                                        </mat-label>
                                        <input matInput type="number" formControlName="targetUpperBound" class="target"
                                            autocomplete="off" />
                                        <mat-error *ngIf="dayForm.controls.targetUpperBound.errors?.greaterThan"
                                            translate="numbers.errorTargetMustBeHigher"></mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                            <div *ngIf="allowWeeklyTargetsControl.value" @fadeExpand [@.disabled]="disableAnimations">
                                <fieldset formGroupName="weekTargets">
                                    <legend translate="numbers.weeklyTargets"></legend>
                                    <div class="week-targets-container">
                                        <div class="week-target" *ngFor="let week of weeks" [formGroupName]="week.toString()">
                                            <div *ngIf="hasUpperTarget">
                                                <mat-form-field [floatLabel]="targetIsSet(dayForm.controls.targetUpperBound.value) ? 'always' : 'auto'" class="mat-form-field-short mat-form-field-narrow">
                                                    <mat-label>
                                                        {{ 'numbers.weekTarget' | translate: { week: week } }}
                                                    </mat-label>
                                                    <input matInput type="number" formControlName="upper" class="target"
                                                        [placeholder]="dayForm.controls.targetUpperBound.value?.toString() ?? ''" autocomplete="off" [name]="'upperTarget_' + week" />
                                                    <mat-hint *ngIf="hasLowerTarget"
                                                        translate="numbers.high"></mat-hint>
                                                    <mat-error *ngIf="dayForm.controls.weekTargets.controls[week.toString()].controls.upper.errors?.greaterThan"
                                                        translate="numbers.errorTargetInvalid"></mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="hasLowerTarget">
                                                <mat-form-field [floatLabel]="targetIsSet(dayForm.controls.targetLowerBound.value) ? 'always' : 'auto'" class="mat-form-field-short mat-form-field-narrow">
                                                    <mat-label>
                                                        {{ 'numbers.weekTarget' | translate: { week: week } }}
                                                    </mat-label>
                                                    <input matInput type="number" formControlName="lower" class="target"
                                                        [placeholder]="dayForm.controls.targetLowerBound.value?.toString() ?? ''" autocomplete="off" [name]="'lowerTarget_' + week" />
                                                    <mat-hint *ngIf="hasUpperTarget"
                                                        translate="numbers.low"></mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </fieldset>
                    }
                </ng-container>
            </fieldset>
        </div>

        <app-recurrence-control *ngIf="hasRecurrence && (schedulingEnabled$ | async)" [formControl]="recurrenceControl" name="recurrence" required>
        </app-recurrence-control>

        @if (workInstructionEnabled$ | async) {
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field>
                        <mat-label>{{ 'numbers.workInstruction' | translate }}</mat-label>
                        <textarea matInput type="text" formControlName="workInstruction" autocomplete="off" maxlength="1000"
                            cdkTextareaAutosize cdkAutosizeMinRows="2">
                        </textarea>
                        <wf-help-indicator matSuffix [matTooltip]="'numbers.workInstructionTooltip' | translate"></wf-help-indicator>
                    </mat-form-field>
                </div>
            </div>

            @if (isSuperAdmin() && workInstructionControl.value) {
                <div class="row" @fadeExpand [@.disabled]="disableAnimations">
                    <div class="col-sm-12">
                        <mat-form-field>
                            <mat-label>{{ 'numbers.workInstructionLink' | translate }}</mat-label>
                            <input matInput type="url" name="workInstructionLink" formControlName="workInstructionLink" autocomplete="off"
                                maxlength="600" placeholder="https://example.com" />
                            <mat-error *ngIf="workInstructionLinkControl.hasError('pattern')" translate="numbers.workInstructionLinkInvalid"></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            }
        }


        <div class="row">
            <div class="col-sm-12">
                <div>
                    <mat-checkbox name="isPrivate" formControlName="isPrivate">
                        {{'numbers.privateNumber' | translate}}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="isRecurring">
                        {{ 'numbers.recurringNumber' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        
        @if (delegationVisible$ | async) {
            <div class="row">
                <div class="col-sm-12">
                    <mat-checkbox formControlName="isDelegated">
                        {{'delegation.delegateToAnotherTeam' | translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="delegation-container" *ngIf="isDelegatedControl.value" @fadeExpand [@.disabled]="disableAnimations">
                <fieldset formGroupName="delegation">
                    <legend>{{'delegation.delegation' | translate}}</legend>
                    <div class="delegation-row">
                        <mat-form-field>
                            <mat-label>{{'delegation.team' | translate}}</mat-label>
                            @if (form.enabled) {
                                <app-auto-select formControlName="team" [options]="delegationTeams$ | async"
                                    [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData" required>
                                    <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                                </app-auto-select>
                            } @else {
                                <input matInput type="text" [value]="getTeamDisplay(delegationTeamControl.value)" disabled required />
                            }
                            <mat-error *ngIf="!delegationTeamControl.valid"
                                translate="delegation.required"></mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'delegation.assignee' | translate}}</mat-label>
                            @if (form.enabled) {
                                <app-auto-select formControlName="assignee" [options]="delegationUsers$ | async"
                                    [optionDisplayFunc]="getUserName" [compareWith]="compareUsers" required>
                                </app-auto-select>
                            } @else {
                                <input matInput type="text" [value]="getUserName(delegationAssigneeControl.value)" disabled required />
                            }
                            <mat-error *ngIf="!delegationAssigneeControl.valid"
                                translate="delegation.required"></mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'delegation.responsibility' | translate}}</mat-label>
                            <mat-select formControlName="responsibility">
                                <mat-option *ngFor="let responsibility of delegationResponsibilities" [value]="responsibility">
                                    {{ getDelegationResponsibilityNameKey(responsibility) | translate }}
                                </mat-option>
                            </mat-select>
                            <app-delegation-responsibility-help-indicator matSuffix></app-delegation-responsibility-help-indicator>
                        </mat-form-field>
                    </div>
                </fieldset>
            </div>
        }

        <div class="row" *ngIf="triggeredDiscussionsEnabled$ | async">
            <div class="col-xs-12 trigger-discussion-row">
                <mat-checkbox formControlName="triggerDiscussion">
                    {{ 'numbers.triggerDiscussion.label' | translate }}
                </mat-checkbox>
                <mat-form-field class="trigger-discussion-type" subscriptSizing="dynamic">
                    <mat-select disableOptionCentering formControlName="triggerDiscussionType">
                        <mat-option value="always">
                            {{ 'numbers.triggerDiscussion.everyWeek' | translate }}
                        </mat-option>
                        <mat-option value="offtarget">
                            {{ 'numbers.triggerDiscussion.whenOffTarget' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="triggerDiscussionTypeControl.value === 'offtarget'">
                    <span @fadeIn>{{ 'numbers.triggerDiscussion.for' | translate }}</span>
                    <mat-form-field class="trigger-discussion-after" subscriptSizing="dynamic" @fadeIn>
                        <mat-select disableOptionCentering formControlName="triggerDiscussionAfter">
                            <mat-option *ngFor="let weeks of triggerDiscussionAfterUpdatesOptions" [value]="weeks" [ngSwitch]="weeks">
                                <ng-container *ngSwitchCase="1">
                                    {{ 'numbers.triggerDiscussion.week' | translate: { weeks } }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ 'numbers.triggerDiscussion.weeks' | translate: { weeks } }}
                                </ng-container>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>

        <div class="row" *ngIf="form.errors">
            <div class="col-xs-12" role="alert">
                <p class="warning-box" *ngIf="form.errors?.capExceeded" translate="numbers.capExceeded"></p>
                <p class="warning-box" *ngIf="form.errors?.capReached" translate="numbers.capReached"></p>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <app-status-button type="submit" [state]="buttonState" [disabled]="form.disabled">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>