<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>{{ (isAucbg ? "goals.quarterlyTarget" : "goals.goal") | translate }}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{'goals.heading' | translate}}</mat-label>
                    <textarea matInput type="text" name="heading" formControlName="heading" autocomplete="off"
                        maxlength="250" required cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
                    <mat-error *ngIf="headingControl.hasError('required')"
                        translate="goals.headingRequired"></mat-error>
                    <mat-error *ngIf="headingControl.errors?.maxlength"
                        translate="goals.headingMaxLength"
                        [translateParams]="headingControl.errors?.maxlength"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{'goals.description' | translate}}</mat-label>
                    <textarea matInput type="text" name="goalDescription" formControlName="description"
                        autocomplete="off" maxlength="1000" required cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
                    <mat-error *ngIf="descriptionControl.hasError('required')"
                        translate="goals.descriptionRequired"></mat-error>
                    <mat-error *ngIf="descriptionControl.errors?.maxlength"
                        translate="goals.descriptionMaxLength"
                        [translateParams]="descriptionControl.errors?.maxlength"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ 'goals.team' | translate }}</mat-label>
                    @if (teamControl.enabled) {
                        <app-auto-select name="team" formControlName="team" [options]="teams$ | async"
                            [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData" required>
                            <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getTeamDisplay(teamControl.value)" disabled required />
                    }
                    <mat-error *ngIf="!teamControl.valid"
                        translate="goals.teamRequired"></mat-error>
                </mat-form-field>
            </div>
            
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'goals.qtr' | translate}}</mat-label>
                    <wf-quarter-field name="goalQtr" formControlName="quarter"
                        [companyId]="teamControl.value?.company?.id" [financialYear]="fixedFinancialYear" required>
                    </wf-quarter-field>
                    <mat-error *ngIf="quarterControl.hasError('required')"
                        translate="goals.qtrRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="datePickerBox">
                    <mat-label>{{'goals.dueDate' | translate}}</mat-label>
                    <input matInput readonly name="dueDate" formControlName="dueDate" [matDatepicker]="picker"
                        (focus)="picker.open()" required />
                    <mat-hint class="due-date-warning" *ngIf="selectedDateOutsideQuarter$ | async" @fadeIn
                        translate="goals.dueDateOutsideQuarter"
                        [translateParams]="{ quarter: quarterControl.value?.quarter }"></mat-hint>
                    <mat-error *ngIf="dueDateControl.hasError('required')"
                        translate="goals.dueDateRequired"></mat-error>
                </mat-form-field>
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'goals.planningStatus' | translate}}</mat-label>
                    <mat-select disableOptionCentering name="status" formControlName="goalStatus" required>
                        <mat-option *ngFor="let status of planningStatuses" [value]="status">
                            {{ getPlanningStatusNameKey(status) | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="goalStatusControl.hasError('required')"
                        translate="goals.statusRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'goals.owner' | translate}}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select disableOptionCentering name="goalOwner" formControlName="owner"
                            [options]="users$ | async" [optionValueFunc]="getUserId" [optionDisplayFunc]="getUserName"
                            required>
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="getUserName(originalOwner)" disabled required />
                    }
                    <mat-error *ngIf="ownerControl.hasError('required')"
                        translate="goals.ownerRequired"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'goals.department' | translate}}</mat-label>
                    @if (form.enabled) {
                        <app-auto-select name="department" formControlName="department" [options]="departments$ | async"
                            [optionValueFunc]="getDepartmentId" [optionDisplayFunc]="getDepartmentName">
                        </app-auto-select>
                    } @else {
                        <input matInput type="text" [value]="originalDepartment?.name" disabled />
                    }
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            @if (form.enabled && (categories$ | async); as categories) {
                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{'goals.category' | translate}}</mat-label>
                        <app-auto-select name="category" formControlName="category" [options]="categories"
                            [optionValueFunc]="getCategoryId" [optionDisplayFunc]="getCategoryDisplay">
                        </app-auto-select>
                    </mat-form-field>
                </div>
    
                <div class="col-sm-6" *ngIf="subCategories$ | async; let subCategories">
                    <mat-form-field>
                        <mat-label>{{'goals.subCategory' | translate}}</mat-label>
                        <app-auto-select name="subCategory" formControlName="subCategory" [options]="subCategories"
                            [optionValueFunc]="getSubCategoryId" [optionDisplayFunc]="getSubCategoryDisplay">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            } @else if (form.disabled && originalCategory) {
                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{'goals.category' | translate}}</mat-label>
                        <input matInput type="text" [value]="originalCategory.description" disabled />
                    </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="originalSubCategory">
                    <mat-form-field>
                        <mat-label>{{'goals.subCategory' | translate}}</mat-label>
                        <input matInput type="text" [value]="originalSubCategory.description" disabled required />
                    </mat-form-field>
                </div>
            }

            @if (annualPlanningEnabled()) {
                @if (form.enabled) {
                    <!-- If we have a strategy list, start the section on a new line. -->
                    <div class="clearfix" *ngIf="strategies$ | async"></div>

                    <div class="col-sm-6">
                        <mat-form-field>
                            <mat-label>
                                {{ (isAucbg ? 'goals.linkToAnnualBusinessGoal' : 'goals.annualBusinessGoal') | translate }}
                            </mat-label>
                            <mat-select disableOptionCentering name="businessGoal" formControlName="annualGoal">
                                <mat-option [value]="null">{{'goals.none' | translate}}</mat-option>
                                <mat-option *ngFor="let businessGoal of myAnnualGoals$ | async" [value]="businessGoal.id">
                                    {{ businessGoal.heading }}
                                </mat-option>
                                <div *ngIf="showAnnualGoalSeparator$ | async" class="separator-option"></div>
                                <mat-option *ngFor="let businessGoal of otherAnnualGoals$ | async" [value]="businessGoal.id">
                                    {{ businessGoal.heading }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6" *ngIf="strategies$ | async; let strategies">
                        <mat-form-field>
                            <mat-label>{{'goals.strategies' | translate}}</mat-label>
                            <mat-select disableOptionCentering name="strategies" formControlName="strategy">
                                <mat-option *ngFor="let strategies of strategies" [value]="strategies.id">
                                    {{ strategies.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                } @else {
                    <!-- If we have a strategy list, start the section on a new line. -->
                    <div class="clearfix" *ngIf="originalStrategy"></div>

                    <div class="col-sm-6">
                        <mat-form-field>
                            <mat-label>
                                {{ (isAucbg ? 'goals.linkToAnnualBusinessGoal' : 'goals.annualBusinessGoal') | translate }}
                            </mat-label>
                            <input matInput type="text" [value]="originalAnnualGoal?.heading" disabled />
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6" *ngIf="originalStrategy">
                        <mat-form-field>
                            <mat-label>{{'goals.strategies' | translate}}</mat-label>
                            <input matInput type="text" [value]="originalStrategy.description" disabled />
                        </mat-form-field>
                    </div>
                }
            }
        </div>
        <ng-container *ngIf="isAucbg" formGroupName="extras">
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field>
                        <mat-label>{{'goals.extras.riskIfNotCompleted' | translate}}</mat-label>
                        <textarea matInput type="text" name="riskIfNotCompleted" formControlName="riskIfNotCompleted"
                            autocomplete="off" maxlength="250"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <!-- Resource Type-->
                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{'goals.extras.resourceRequired' | translate}}</mat-label>
                        <mat-select formControlName="resourceRequired" name="resource" disableOptionCentering
                            required>
                            <mat-option *ngFor="let resource of resourceTypes" [value]="resource">
                                {{ getResourceTypeNameKey(resource) | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="resourceRequiredControl.hasError('required')"
                            translate="goals.extras.resourceRequired"></mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <mat-form-field>
                        <mat-label>{{'goals.extras.successMetric' | translate}}</mat-label>
                        <input matInput type="text" name="successMetric" formControlName="successMetric"
                            autocomplete="off" maxlength="250" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row" [ngSwitch]="resourceRequiredControl.value">
                <ng-container *ngSwitchCase="ResourceType.new" formGroupName="newResource">
                    <div class="col-sm-6">
                        <mat-form-field>
                            <mat-label>{{'goals.extras.budgetCost' | translate}}</mat-label>
                            <input matInput type="number" name="budgetCost" formControlName="cost" autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field>
                            <mat-label>{{'goals.extras.comment' | translate}}</mat-label>
                            <input matInput type="text" name="comment" formControlName="comment" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="ResourceType.internal" formGroupName="internalResource">
                    <div class="col-sm-6">
                        <mat-form-field>
                            <mat-label>{{'goals.extras.who' | translate}}</mat-label>
                            <input matInput type="text" name="who" formControlName="who" autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field>
                            <mat-label>{{'goals.extras.hours' | translate}}</mat-label>
                            <input matInput type="number" name="hours" formControlName="hours" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <div class="row">
            <!-- Capture Frequency -->
            <div class="col-sm-6" *ngIf="schedulingVisible()">
                <mat-form-field *ngIf="schedulingEnabled(); else fakeSchedule">
                    <mat-label>{{ "updateScheduleType.title" | translate }}</mat-label>
                    <mat-select [formControl]="scheduleTypeControl" name="updateScheduleType" required>
                        <mat-option *ngFor="let t of updateScheduleTypes" [value]="t">
                            {{ getUpdateScheduleTypeNameKey(t) | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-template #fakeSchedule>
                    <app-fake-schedule-field></app-fake-schedule-field>
                </ng-template>
            </div>

            <div class="col-sm-6" *ngIf="noteEnforcementEnabled()">
                <mat-form-field>
                    <mat-label>{{ 'goals.requireNote' | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="requireNote" required>
                        <mat-option *ngFor="let option of requireNoteOptions" [value]="option">
                            {{ getRequireNoteNameKey(option) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <app-recurrence-control *ngIf="hasRecurrence && schedulingEnabled()" [formControl]="recurrenceControl" name="recurrence" required>
        </app-recurrence-control>

        <div class="row">
            <div class="col-sm-6">
                <div>
                    <mat-checkbox name="isPrivateGoal" formControlName="isPrivate">
                        {{'goals.personalGoal' | translate}}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox name="isRecurringGoal" formControlName="isRecurring">
                        {{(isAucbg ? 'goals.recurringTarget' : 'goals.recurringGoal') | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        
        @if (delegationVisible$ | async) {
            <div class="row">
                <div class="col-sm-12">
                    <mat-checkbox formControlName="isDelegated">
                        {{'delegation.delegateToAnotherTeam' | translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="delegation-container" *ngIf="isDelegatedControl.value" @fadeExpand [@.disabled]="disableAnimations">
                <fieldset formGroupName="delegation">
                    <legend>{{'delegation.delegation' | translate}}</legend>
                    <div class="delegation-row">
                        <mat-form-field>
                            <mat-label>{{'delegation.team' | translate}}</mat-label>
                            @if (form.enabled) {
                                <app-auto-select formControlName="team" [options]="delegationTeams$ | async"
                                    [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData" required>
                                    <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                                </app-auto-select>
                            } @else {
                                <input matInput type="text" [value]="originalDelegation?.team?.name" disabled required />
                            }
                            <mat-error *ngIf="!delegationTeamControl.valid"
                                translate="delegation.required"></mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'delegation.assignee' | translate}}</mat-label>
                            @if (form.enabled) {
                                <app-auto-select formControlName="assignee" [options]="delegationUsers$ | async" [optionValueFunc]="getUserId"
                                    [optionDisplayFunc]="getUserName" required>
                                </app-auto-select>
                            } @else {
                                <input matInput type="text" [value]="getUserName(originalDelegation?.assignee)" disabled required />
                            }
                            <mat-error *ngIf="!delegationAssigneeControl.valid"
                                translate="delegation.required"></mat-error>
                        </mat-form-field>
                    </div>
                </fieldset>
            </div>
        }

        <div class="row" *ngIf="triggeredDiscussionsEnabled()">
            <div class="col-xs-12 trigger-discussion-row">
                <mat-checkbox formControlName="triggerDiscussion">
                    {{ 'goals.triggerDiscussion.label' | translate }}
                </mat-checkbox>
                <mat-form-field class="trigger-discussion-type" subscriptSizing="dynamic">
                    <mat-select disableOptionCentering formControlName="triggerDiscussionType">
                        <mat-option value="always">
                            {{ 'goals.triggerDiscussion.everyWeek' | translate }}
                        </mat-option>
                        <mat-option value="offtarget">
                            {{ 'goals.triggerDiscussion.whenOffTarget' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="triggerDiscussionTypeControl.value === 'offtarget'">
                    <span @fadeIn>{{ 'goals.triggerDiscussion.for' | translate }}</span>
                    <mat-form-field class="trigger-discussion-after" subscriptSizing="dynamic" @fadeIn>
                        <mat-select disableOptionCentering formControlName="triggerDiscussionAfter">
                            <mat-option *ngFor="let weeks of triggerDiscussionAfterUpdatesOptions" [value]="weeks" [ngSwitch]="weeks">
                                <ng-container *ngSwitchCase="1">
                                    {{ 'goals.triggerDiscussion.week' | translate: { weeks } }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ 'goals.triggerDiscussion.weeks' | translate: { weeks } }}
                                </ng-container>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button class="round-button suggest-button" (click)="suggestImprovement()" *ngIf="suggestionsEnabled()"
            [disabled]="!headingControl.valid">
            <mat-icon>tips_and_updates</mat-icon>
            {{ 'goals.suggest.suggestImprovement' | translate }}
        </button>
        <app-status-button type="submit" [state]="buttonState" [disabled]="form.disabled">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>